// src/components/ButtonOptions.js
import React from 'react';
import './ButtonOptions.css';
import MenuIcon from '../../assets/icons/menu.svg';

const OptionsButton = ({ onClick }) => {
  return (
    <div className="optionsbutton" onClick={onClick}>
      <img src={MenuIcon} alt="Menu" className="menu-icon" />
    </div>
  );
};

export default OptionsButton;
