// src/components/SubHeader.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import './SubHeader.css';
import OptionsButton from './ButtonOptions';
import SlideUpModal from '../components/SlideUpModal';

const SectionHeader = ({ title = 'Header', modalContent = null, onCloseModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate(); // Initialize navigate for navigation

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (onCloseModal) handleCloseModal(); // Close the slideupmodal if requested from parent state
  }, [onCloseModal]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="subheader">
        <div className="subheader-content">
          <h3 className="section-title">{title}</h3>
          {modalContent && <OptionsButton onClick={handleOpenModal} />} {/* Only show if modalContent is provided */}
        </div>
        <div className="header-extra">
          {/* Optional additional content goes here */}
        </div>
      </div>

      {/* Slide-up modal */}
      <SlideUpModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Options"
      >
        {/* Modal content */}
        {modalContent}
      </SlideUpModal>
    </>
  );
};

export default SectionHeader;
