// src/components/menu/changeLocation.js
import React, { useState, useEffect } from 'react';
import SlideUpModal from '../components/SlideUpModal';
import Checkbox from '../components/Checkbox';
import { db } from '../../firebaseConfig'; // Import Firestore
import { doc, setDoc } from 'firebase/firestore';
import { getFirestore, collection, getDocs } from "firebase/firestore";

const ChangeLocation = ({ isOpen, onClose, selectedCounties, setSelectedCounties }) => {
  const [counties, setCounties] = useState([]);
  const [tempSelectedCounties, setTempSelectedCounties] = useState([]);
  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    const fetchCounties = async () => {
      const db = getFirestore();
      const countiesSnapshot = await getDocs(collection(db, 'counties'));
      const countiesList = countiesSnapshot.docs.map(doc => doc.data().name);
      setCounties(countiesList);
    };
    fetchCounties();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTempSelectedCounties(selectedCounties);
      setValidationError(false);
    }
  }, [isOpen, selectedCounties]);

  const handleCountySelection = (selected) => {
    setTempSelectedCounties(selected);
  };

  const handleCloseModal = async () => {
    if (tempSelectedCounties.length === 0) {
      setValidationError(true);
    } 
    else if (JSON.stringify(tempSelectedCounties) === JSON.stringify(selectedCounties)){
      onClose(); // No changes
    }
    else {
      // Determine if the flag `selectedContiesOutdated` should be true or false
      const addedCounties = tempSelectedCounties.filter(
        (county) => !selectedCounties.includes(county)
      );
      const outdatedFlag = addedCounties.length > 0;

      setSelectedCounties(tempSelectedCounties);

      // Save to local storage
      localStorage.setItem('selectedCounties', JSON.stringify(tempSelectedCounties));
      localStorage.setItem('selectedCountiesOutdated', outdatedFlag);

      // Check if user is signed in by looking for a user ID in local storage
      const userId = localStorage.getItem('userId');
      if (userId) {
        // Save to Firestore
        try {
          const userDocRef = doc(db, 'users', userId);
          await setDoc(userDocRef, { selectedCounties: tempSelectedCounties }, { merge: true });
        } catch (error) {
          console.error('Error saving selected counties to Firestore:', error);
        }
      }

      onClose();
    }
  };

  return (
    <SlideUpModal isOpen={isOpen} onClose={handleCloseModal} title="Location">
      <p style={{ color: validationError ? 'red' : 'inherit' }}>
        {validationError ? 'Select at least one location' : 'Select the locations you’re interested in.'}
      </p>
      <Checkbox
        labels={counties}
        selectedLabels={tempSelectedCounties}
        onSelectionChange={handleCountySelection}
      />
    </SlideUpModal>
  );
};

export default ChangeLocation;
