// src/components/StandaloneNavigation.js
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const StandaloneNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Detect if running as PWA and redirect to home page if needed
  useEffect(() => {
    const checkIfStandalone = () => {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        if (location.pathname === '/install' || location.pathname === '/clear-cache') {
          navigate('/'); // Automatically navigate to home screen
        }
      }
    };

    checkIfStandalone(); // Check on mount

    window.addEventListener('resize', checkIfStandalone);

    return () => {
      window.removeEventListener('resize', checkIfStandalone);
    };
  }, [location.pathname, navigate]);

  return null; // This component doesn't render anything, it only handles logic
};

export default StandaloneNavigation;
