// src/components/components/Button.js
import React from 'react';
import './Button.css';

const Button = ({ variant = 'primary', label, icon, onClick, disabled = false, className }) => {
  const buttonClass = `btn btn-${variant} ${!label ? 'btn-icon-only' : ''} ${
    disabled ? 'disabled' : ''} ${className}`;

  const handleClick = (e) => {
    // Allow click if onClick exists, regardless of the disabled state
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button className={buttonClass} onClick={handleClick} disabled={disabled}>
      {icon && (
        <span className="button-icon">
          <img src={icon} alt={label || 'button icon'} />
        </span>
      )}
      {label && <span className="button-label">{label}</span>}
    </button>
  );
};

export default Button;
