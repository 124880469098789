// src/components/components/ListButton.js
import React from 'react';
import PropTypes from 'prop-types';
import iconNext from '../../assets/icons/chevron-next.png';
import './ListButton.css';

const ListButton = ({ text, onClick, icon, showChevron = true }) => (
  <div className="list-button-container">
    <button className="list-button" onClick={onClick}>
      {icon && <img src={icon} alt="Icon" className="left-icon" />} {/* Left icon, if provided */}
      <span className="list-button-text">{text}</span>
      {showChevron && <img src={iconNext} alt="Next" className="chevron-icon" />} {/* Right chevron */}
    </button>
    <hr className="button-separator" />
  </div>
);

ListButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.string,           // Optional icon for the left side
  showChevron: PropTypes.bool,       // Toggle for the right chevron
};

export default ListButton;
