// src/components/menu/changeDance.js
import React, { useState, useEffect } from 'react';
import SlideUpModal from '../components/SlideUpModal';
import Checkbox from '../components/Checkbox';
import { listOfDanceTypes } from '../../data/danceTypes';
import { db } from '../../firebaseConfig'; // Import Firestore
import { doc, setDoc } from 'firebase/firestore'; // Import Firestore functions

const ChangeDance = ({ isOpen, onClose, selectedDances, setSelectedDances }) => {
  const [tempSelectedDances, setTempSelectedDances] = useState([]); // Temporary state for selections
  const [validationError, setValidationError] = useState(false); // State to track validation error

  useEffect(() => {
    if (isOpen) {
      setTempSelectedDances(selectedDances);
      setValidationError(false);
    }
  }, [isOpen, selectedDances]);

  const handleDanceSelection = (selected) => {
    setTempSelectedDances(selected);
  };

  const handleCloseModal = async () => {
    if (tempSelectedDances.length === 0) {
      setValidationError(true);
    } 
    else if (JSON.stringify(tempSelectedDances) === JSON.stringify(selectedDances)){
      onClose(); // No changes
    }
    else {
      // Determine if the flag `selectedDancesOutdated` should be true or false
      const addedDances = tempSelectedDances.filter(
        (dance) => !selectedDances.includes(dance)
      );
      const outdatedFlag = addedDances.length > 0;

      setSelectedDances(tempSelectedDances);
      
      // Save to local storage
      localStorage.setItem('selectedDances', JSON.stringify(tempSelectedDances));
      localStorage.setItem('selectedDancesOutdated', outdatedFlag);

      

      // Check if user is signed in by looking for a user ID in local storage
      const userId = localStorage.getItem('userId');
      if (userId) {
        // Save to Firestore
        try {
          const userDocRef = doc(db, 'users', userId);
          await setDoc(userDocRef, { selectedDances: tempSelectedDances }, { merge: true });
        } catch (error) {
          console.error('Error saving selected dances to Firestore:', error);
        }
      }

      onClose();
    }
  };

  return (
    <SlideUpModal isOpen={isOpen} onClose={handleCloseModal} title="Dance Type">
      <p style={{ color: validationError ? 'red' : 'inherit' }}>
        {validationError ? 'Select at least one dance' : 'Select the dances you’re interested in.'}
      </p>
      <Checkbox
        labels={listOfDanceTypes}
        selectedLabels={tempSelectedDances}
        onSelectionChange={handleDanceSelection}
      />
    </SlideUpModal>
  );
};

export default ChangeDance;
