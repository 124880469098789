// src/components/events/eventFetchSingle.js
// This component will fetch a single event and add it to indexed db
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { openDB } from "idb";
import firebaseConfig from "../../firebaseConfig";

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Initialize and retrieve the IndexedDB database
const getDB = async () => {
  return await openDB("EventDB", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("events")) {
        const store = db.createObjectStore("events", { keyPath: "eventId" });
        store.createIndex("recurrenceDay", "recurrenceDay", { unique: false });
        store.createIndex("eventDate", "eventDate", { unique: false });
        store.createIndex("eventDays", "eventDays", { unique: false });
        store.createIndex("shortLocation", "shortLocation", { unique: false });
      }
    },
  });
};

// Fetch and store a single event in IndexedDB
export const eventFetchSingle = async (eventId) => {
  try {
    if (!eventId) {
      throw new Error("An event ID must be provided.");
    }

    // Get the event from Firestore
    const eventDoc = doc(db, "events", eventId);
    const eventSnapshot = await getDoc(eventDoc);

    if (!eventSnapshot.exists()) {
      console.error(`Event with ID ${eventId} does not exist.`);
      return;
    }

    // Map the Firestore document to the event data model
    const eventData = mapEventData(eventSnapshot);

    // Save the event to IndexedDB
    const dbInstance = await getDB();
    const tx = dbInstance.transaction("events", "readwrite");
    await tx.store.put(eventData);
    await tx.done;

    console.log(`Single Event with ID ${eventId} successfully stored in IndexedDB.`);
  } catch (err) {
    console.error("Single Event Error during fetch:", err);
  }
};

// Function to map Firestore document to event data model
const mapEventData = (doc) => {
  const data = doc.data();
  return {
    eventId: doc.id,
    creator: data.creator,
    approved: data.approved,
    announcementText: data.announcementText,
    isCancelled: data.isCancelled,
    eventName: data.eventName,
    shortLocation: data.shortLocation,
    imageUrl: data.imageUrl,
    thumbnailUrl: data.thumbnailUrl,
    timestamp: data.timestamp,
    description: data.description,
    team: data.team,
    danceTypes: data.danceTypes,
    isRecurring: data.isRecurring,
    recurrenceDay: data.recurrenceDay,
    eventDate: data.eventDate,
    eventEndDate: data.eventEndDate,
    eventDays: data.eventDays,
    startTime: data.startTime,
    endTime: data.endTime,
    location: {
      venue: data.location.venue,
      street: data.location.street,
      city: data.location.city,
      county: data.location.county,
      postcode: data.location.postcode,
      state: data.location.state,
      country: data.location.country,
      description: data.location.description,
    },
    agenda: data.agenda || [],
    pricing: data.pricing || [],
    exceptions: data.exceptions || []
  };
};

export default eventFetchSingle;
