// src/pages/CalendarEvents.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './WeeklyEvents.css';
import PageHeader from '../components/layout/PageHeader';
import PlusIcon from '../assets/icons/icon-plus-white.png';
import LocationIcon from '../assets/icons/icon-location-white.png';
import DanceIcon from '../assets/icons/icon-dance-white.png';
import Button from '../components/components/Button';
import Tag from '../components/components/Tag';
import ChangeLocation from '../components/menu/changeLocation';
import ChangeDance from '../components/menu/changeDance';
import LoaderSmall from '../assets/loader-small.svg';
import EventCard from '../components/event-display/EventCardLarge';
import PlaceholderNoEvent from '../components/event-display/EventCard-NoEvent';
import eventStorage from '../components/events/eventStorage';
import { format, addDays, addMonths, startOfMonth, endOfMonth, getMonth, getYear, parseISO } from 'date-fns';

const MONTH_NAMES = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const CalendarEvents = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // Control PageHeader modal
  const [isCountyModalOpen, setIsCountyModalOpen] = useState(false); // Control county modal
  const [isDanceModalOpen, setIsDanceModalOpen] = useState(false); // Control dance selection modal
  const [selectedCounties, setSelectedCounties] = useState([]); // Store selected counties
  const [selectedDances, setSelectedDances] = useState([]); // Store selected dances
  const [eventsByMonth, setEventsByMonth] = useState({});
  const [loadingMonths, setLoadingMonths] = useState({});

  // Initialize months, starting from current month
  const getMonths = () => {
    const today = new Date();
    return Array.from({ length: 12 }, (_, i) => {
      const date = addMonths(today, i);
      return {
        // monthName: i === 1 ? 'This Month' : MONTH_NAMES[date.getMonth()], // Replace current month with "This Month"
        monthName: MONTH_NAMES[date.getMonth()],
        monthNumber: date.getMonth() + 1, // Months are 0-indexed, so add 1
        dateString: format(date, 'yyyy-MM')
      };
    });
  };
  
  const months = getMonths();
  

 // Fetch events for each month
 useEffect(() => {
  setTimeout(() => {
  // Only proceed if there is at least one dance and one county selected
  if (selectedDances.length > 0 && selectedCounties.length > 0) {
    const fetchEventsForMonth = async (monthNumber, dateString) => {
      setLoadingMonths((prev) => ({ ...prev, [monthNumber]: true }));

      const filtersoneoff = {
        month: dateString, // Return all events for this month
        isRecurring: false,
        // approved: true
      };
      const oneOffEvents = await eventStorage({
        filters: filtersoneoff,
        onlyDances: true,
        onlyCounties: true,
        occurrenceDate: dateString, 
      });

      const filteredEvents = [...oneOffEvents]; // Can add additional events to here in the future

      // Sort events by eventDate
      const sortedEvents = filteredEvents.sort((a, b) => {
        const dateA = new Date(a.eventDate);
        const dateB = new Date(b.eventDate);
        return dateA - dateB; // Ascending order
      });

      // Update state with the filtered events for the specific month
      setEventsByMonth((prev) => ({ ...prev, [monthNumber]: sortedEvents }));
      setLoadingMonths((prev) => ({ ...prev, [monthNumber]: false }));
    };

    months.forEach(({ monthNumber, dateString }) => {
      fetchEventsForMonth(monthNumber, dateString);
    });
  }
}, 1);  // Delay by 1ms for reliable cache access
}, [selectedDances, selectedCounties]); // Refetch when filters change


  // Load selected counties and dances from localStorage on component mount
  useEffect(() => {
    setTimeout(() => {
      const cachedCounties = localStorage.getItem('selectedCounties');
      if (cachedCounties) {
        setSelectedCounties(JSON.parse(cachedCounties));
      }

      const cachedDances = localStorage.getItem('selectedDances');
      if (cachedDances) {
        setSelectedDances(JSON.parse(cachedDances));
      }
    }, 1); // Delay by 1ms for reliable cache access
  }, []);

  // Modal content for PageHeader
  const modalContent = (
    <div className="layout">
      {/* <Button
        variant="primary"
        label="Create event"
        icon={PlusIcon}
        onClick={() => navigate('/create-event')}
      /> */}
      <Button
        variant="primary"
        label="Change location"
        icon={LocationIcon}
        onClick={() => {
          setIsModalOpen(false); // Close PageHeader modal
          setIsCountyModalOpen(true); // Open county modal
        }} 
      />
      <Button
        variant="primary"
        label="Change dance"
        icon={DanceIcon} // Use a dance icon
        onClick={() => {
          setIsModalOpen(false); // Close PageHeader modal
          setIsDanceModalOpen(true); // Open dance modal
        }} 
      />
    </div>
  );

  return (
    <div className="weekly-events-page">
      <PageHeader 
        title="Upcoming events" 
        modalContent={modalContent} 
        isModalOpen={isModalOpen} 
        setIsModalOpen={setIsModalOpen} // Pass control of modal state
      />
       
      <div className="page-content">
        {/* Display selected counties as tags */}
        <div className="filter-tags">
          {selectedCounties.map((county, index) => (
            <Tag 
              key={index} 
              label={county} 
              dismissible={false} 
              onClick={() => setIsCountyModalOpen(true)} // Open county modal
            />
          ))}
        </div>

        {months.map(({ monthName, monthNumber, dateString }) => (
          <div key={monthNumber} className="grouped-eventcards">
            <h2>{monthName}</h2>
            {/* <p>{dateString}</p> */}
            {loadingMonths[monthNumber] ? (
              <img src={LoaderSmall} style={{ width: "24px" }} alt="Loading..." />
            ) : eventsByMonth[monthNumber]?.length > 0 ? (
              eventsByMonth[monthNumber].map((event) => (
                <EventCard
                  key={event.eventId}
                  event={event}
                  onClick={() =>
                    navigate(`/view-event/${event.eventId}?date=${event.occurrenceDate}`, { state: { from: '/calendar' } })
                  }
                />
              ))
            ) : (
              <PlaceholderNoEvent />
            )}
          </div>
        ))}
      </div>

      {/* County selection modal */}
      <ChangeLocation
        isOpen={isCountyModalOpen}
        onClose={() => setIsCountyModalOpen(false)}
        selectedCounties={selectedCounties} // Pass selected counties
        setSelectedCounties={setSelectedCounties} // Update counties on close
      />

      {/* Dance selection modal */}
      <ChangeDance
        isOpen={isDanceModalOpen}
        onClose={() => setIsDanceModalOpen(false)}
        selectedDances={selectedDances} // Pass selected dances
        setSelectedDances={setSelectedDances} // Update dances on close
      />

    </div>
  );
};

export default CalendarEvents;
