// src/components/view-event/About.js
import React from 'react';

const About = ({ announcementText, approved, description, team }) => {
  return (
    <div className="layout">
      {!approved && (
      <div className='viewevent-container announcement-container'>
        <h3>Pending approval</h3>
        <p>This event is currently pending approval - this may take up to 48 hours. You don't need to do anything, once approved it will automatically start displaying within the listed events.</p>
        <p>The approval process is just a temporary measure to ensure events created by a user are appropriate. If you have any questions or issues, please contact find.your.rhythm@hotmail.com.</p>
      </div>
      )}

      {announcementText && (
      <div className='viewevent-container announcement-container'>
        <h3>Announcement</h3>
        <p>{announcementText}</p>
      </div>
      )}

      <div className='viewevent-container'>
        <h3>Description</h3>
        <p>{description}</p>
      </div>

      {team && (
      <div className='viewevent-container'>
        <h3>Team</h3>
        <p>{team}</p>
      </div>
      )}

    </div>
  );
};

export default About;
