// src/components/user/AvatarHeader.js
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar';
import './AvatarHeader.css';

const AvatarHeader = ({ name }) => {
  // Extracts the first name from a full name if provided
  const getFirstName = (name) => name.split(' ')[0];

  return (
    <div className="avatar-header">
      <Avatar name={name} />
      <div className="greeting">
        {name ? `Hi, ${getFirstName(name)}!` : 'You are not signed in.'}
      </div>
    </div>
  );
};

AvatarHeader.propTypes = {
  name: PropTypes.string,
};

export default AvatarHeader;
