// src/components/new-event/Branding.js
import React, { useState } from 'react';
import UploadContainer from '../components/UploadContainer';
import './Branding.css';
import RadioButton from '../components/RadioButton';

const Branding = ({ imageUrl, setImageUrl, setImageFile, setThumbnailFile }) => {
  const [scalingMode, setScalingMode] = useState('shrink'); // Set default to 'shrink'

  return (
    <div className="branding-tab layout">
      <h2>Event image</h2>
      <p>Upload an image or banner (jpeg or png) to represent the event. Keep it simple.</p>


      {/* Radio controls for selecting scaling mode */}
      <RadioButton
        labels={['Shrink to fit', 'Crop to fit']}
        selectedLabel={scalingMode === 'shrink' ? 'Shrink to fit' : 'Crop to fit'}
        onSelectionChange={(selectedLabel) => {
          setScalingMode(selectedLabel === 'Shrink to fit' ? 'shrink' : 'crop');
        }}
      />
      {/* <div className="scaling-options">
        <label>
          <input 
            type="radio" 
            value="shrink" 
            checked={scalingMode === 'shrink'}
            onChange={() => setScalingMode('shrink')} 
          />
          Shrink to fit
        </label>
        <label>
          <input 
            type="radio" 
            value="crop" 
            checked={scalingMode === 'crop'}
            onChange={() => setScalingMode('crop')} 
          />
          Crop
        </label>
      </div> */}


      <UploadContainer
        setFile={setImageFile}
        setThumbnailFile={setThumbnailFile}
        previewUrl={imageUrl}
        setPreviewUrl={setImageUrl}
        placeholderText="Upload event image"
        fileTypes="image/jpeg, image/png"
        scalingMode={scalingMode} // Pass scaling mode to UploadContainer
      />

    </div>
  );
};

export default Branding;
