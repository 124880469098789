// src/components/events/eventDays.js
/**
 * Function to get the individual dates within an event date range
 * @param {string} eventDate - Start date in the form yyyy-mm-dd
 * @param {string} eventEndDate - End date in the form yyyy-mm-dd
 * @returns {object} - Returns the individual dates, in the form yyyy-mm-dd, that are between eventDate and eventEndDate inclusive
 */
const calculateEventDays = (eventDate, eventEndDate) => {
    const dates = [];
  
    const [startYear, startMonth, startDay] = eventDate.split('-').map(Number);
    const [endYear, endMonth, endDay] = eventEndDate.split('-').map(Number);
  
    const start = new Date(Date.UTC(startYear, startMonth - 1, startDay));
    const end = new Date(Date.UTC(endYear, endMonth - 1, endDay));
  
    if (isNaN(start) || isNaN(end)) {
      console.error("Invalid date format. Please use yyyy-mm-dd.");
      console.log(eventDate);
      console.log(eventEndDate);
      console.log('Start year ', startYear);
      return [];
    }
  
    if (start > end) {
      console.error("Start date must be before or equal to the end date.");
      return [];
    }
  
    let current = new Date(start);
    while (current <= end) {
      const formattedDate = `${current.getUTCFullYear()}-${String(current.getUTCMonth() + 1).padStart(2, '0')}-${String(current.getUTCDate()).padStart(2, '0')}`;
      dates.push(formattedDate);
      current.setUTCDate(current.getUTCDate() + 1);
    }
  
    return dates;
  };
  
  export default calculateEventDays