// src/components/Login.js
import React, { useState } from 'react';
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import ListButton from '../components/ListButton';
import EmailLoginScreen from './EmailLoginScreen';
import googleIcon from '../../assets/icons/signup-google.png';
import facebookIcon from '../../assets/icons/signup-facebook.png';
import emailIcon from '../../assets/icons/inputicon-email.png';

const Login = ({ onLoginSuccess }) => {
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [error, setError] = useState(null);

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      onLoginSuccess(); // Call onLoginSuccess after successful login
    } catch (error) {
      setError(error.message);
    }
  };

  const handleFacebookLogin = async () => {
    const provider = new FacebookAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      onLoginSuccess(); // Call onLoginSuccess after successful login
    } catch (error) {
      setError(error.message);
    }
  };

  if (showEmailLogin) {
    return <EmailLoginScreen onBack={() => setShowEmailLogin(false)} onLoginSuccess={onLoginSuccess} />;
  }

  return (
    <div className='page-content textBlock'>
      <p>Login to:</p>
      <ul>
        <li>Save your favourite events</li>
        <li>Create and manage events</li>
      </ul>
      <ListButton text="Login with Google" icon={googleIcon} onClick={handleGoogleLogin} />
      {/* <ListButton text="Login with Facebook" icon={facebookIcon} onClick={handleFacebookLogin} /> */}
      <ListButton text="Login with email" icon={emailIcon} onClick={() => setShowEmailLogin(true)} />
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Login;
