// src/pages/CreateEventScreen.js
/* 
Creates a new event, populating the following fields:
    creator: userId,
    eventName: eventName || '',
    shortLocation: shortLocation || '',
    description: description || '',
    team: team || '',
    danceTypes: danceTypes || [],
    isRecurring: isRecurring ?? true,
    recurrenceDay: isRecurring ? selectedDay : null,
    eventDate: !isRecurring ? eventDate || '' : null,
    eventEndDate: eventEndDate || eventDate,
    startTime: startTime || null,
    endTime: endTime || null,
    timestamp: firebaseTimestamp,
    agenda: timeslots.map(slot => ({
      id: slot.id,
      slotName: slot.slotName || '',
      startTime: slot.startTime || null,
      endTime: slot.endTime || null,
      levels: slot.levels || []
    })),
    pricing: priceSlots.map(slot => ({
      id: slot.id,
      itemName: slot.itemName || '',
      prices: slot.prices || {},
    })),
    location: {
      venue: location.venue || '',
      street: location.street || '',
      city: location.city || '',
      county: location.county || '',  // County is part of the location object
      postcode: location.postcode || '',
      state: location.state || '',
      country: location.country || '',
      description: location.description || '',
    },
    isCancelled: false

Sets local storage:
    'eventCreator' = existingEventIds + new eventId;
*/

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, updateDoc, Timestamp, query, where, getDocs, doc, arrayUnion  } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { validateEvent } from '../data/eventValidation'; 
import PageHeader from '../components/layout/PageHeader';
import TopNav from '../components/layout/TopNav';
import Event from '../components/new-event/Event';
import About from '../components/new-event/About';
import Agenda from '../components/new-event/Agenda';
import Pricing from '../components/new-event/Pricing';
import Location from '../components/new-event/Location';
import Branding from '../components/new-event/Branding';
import PageFooter from '../components/layout/PageFooter';
import Button from '../components/components/Button';
import SlideUpModal from '../components/components/SlideUpModal';
import eventImageSave from '../components/edit-event/eventImageSave';
import eventStorage from '../components/events/eventStorage';
import fetchEventImages from '../components/edit-event/getEventImage';

import './CreateEventScreen.css';
import '../components/new-event/new-event.css';

import cancelIcon from '../assets/icons/icon-cancel-brand.png';
import tickIcon from '../assets/icons/icon-tick-white.png';
import firebaseConfig from '../firebaseConfig';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const CreateEventScreen = () => {
  const navigate = useNavigate();

  // If eventId is in URL, use that event as a template
  const locationUrl = useLocation();
  const queryUrl = new URLSearchParams(locationUrl.search);
  const eventIdOfCopy = queryUrl.get('copiedEvent'); // Extract eventId of event to copy (if provided) from url
  const occurrenceOfCopy = queryUrl.get('date');
  const [copiedEventData, setCopiedEventData] = useState(null);
  const [copiedEventImages, setCopiedEventImages] = useState(null);

  // Display modal if they cancel when they have changes/data
  const [isCancelChangesModalOpen, setIsCancelChangesModalOpen] = useState(false);

  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  // Give the user an info pop-up when they start
  useEffect(()=> {
    setTimeout(() => {
        // Get user data
        const permission = localStorage.getItem("permission");
        const eventCount = localStorage.getItem("eventCreator");
        if (permission != 1 && eventCount?.length < 50 && !eventIdOfCopy) {
          setIsIntroModalOpen(true);
        }
    }, 1);  // Delay by 1ms for reliable cache access
  }, []);

  // Get the data from the copied event
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const filters = { eventId: eventIdOfCopy };
        const eventCopy = await eventStorage({
          filters,
          onlyDances: false,
          onlyCounties: false,
          occurrenceDate: occurrenceOfCopy
        });
        setCopiedEventData(eventCopy[0]);
        console.log('The copied event: ', copiedEventData)

      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };
    if (eventIdOfCopy) {
      console.log('Got event ID of copy: ', eventIdOfCopy, occurrenceOfCopy)
      fetchEvent();
    }
  
  }, [eventIdOfCopy, occurrenceOfCopy]);

  // Populate the event by default with the copied event data
  useEffect(() => {
    if (copiedEventData) {
      setEventName(`Copy of ${copiedEventData.eventName}`);
      setShortLocation(copiedEventData.shortLocation);
      setDescription(copiedEventData.description);
      setTeam(copiedEventData.team || '');
      setDanceTypes(copiedEventData.danceTypes || []);
      setIsRecurring(copiedEventData.isRecurring ?? true);
      setSelectedDay(copiedEventData.recurrenceDay || 0);
      setEventDate(copiedEventData.eventDate || '');
      setEventEndDate(copiedEventData.eventEndDate || '');
      setStartTime(copiedEventData.startTime || '');
      setEndTime(copiedEventData.endTime || '');
      setEventDays(copiedEventData.eventDays || []);
      setTimeslots(copiedEventData.agenda || []);
      setPriceSlots(copiedEventData.pricing || []);
      setLocation({
        venue: copiedEventData.location.venue || '',
        street: copiedEventData.location.street || '',
        city: copiedEventData.location.city || '',
        county: copiedEventData.location.county || '',
        postcode: copiedEventData.location.postcode || '',
        state: copiedEventData.location.state || '',
        country: copiedEventData.location.country || '',
        description: copiedEventData.location.description || '', 
      });

      // Descoped for now due to CORS issues
        // setImageUrl(copiedEventData.imageUrl || '');
        // setThumbnailUrl(copiedEventData.thumbnailUrl || '');

    //    if (copiedEventData?.imageUrl && copiedEventData?.thumbnailUrl) {
    //     // Call the async function here
    //     fetchCopiedEventImages(eventIdOfCopy)
    //       .then(eventImages => {
    //         if (eventImages) {
    //           setCopiedEventImages(eventImages);
    //         }
    //       })
    //       .catch(error => console.error("Error fetching event images:", error));
    //   }
      }
  }, [copiedEventData]);

  // // Function to fetch copied event images as async
  // const fetchCopiedEventImages = async (eventId) => {
  //   try {
  //     const eventImages = await fetchEventImages(app, eventId);
  //     return eventImages;
  //   } catch (error) {
  //     console.error("Error fetching event images:", error);
  //     return null; // Or handle the error differently
  //   }
  // };

  // Define the tabs for the top navigation
  const tabs = [
    { id: 'event', label: 'Event' },
    { id: 'about', label: 'About' },
    { id: 'agenda', label: 'Agenda' },
    { id: 'pricing', label: 'Pricing' },
    { id: 'location', label: 'Location' },
    { id: 'branding', label: 'Branding' },
  ];

  // Define form validation
  const [disableSubmit, setDisableSubmit] = useState(false);
  //const [isFormValid, setIsFormValid] = useState(true);
  // Modal state for validation errors
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  // Define form state for data across the tabs
  const [eventName, setEventName] = useState('');
  const [shortLocation, setShortLocation] = useState('');
  const [description, setDescription] = useState('');
  const [team, setTeam] = useState('');
  const [danceTypes, setDanceTypes] = useState([]);
  const [isRecurring, setIsRecurring] = useState(true);
  const [selectedDay, setSelectedDay] = useState(0);
  const [eventDate, setEventDate] = useState('');
  const [eventEndDate, setEventEndDate] = useState('');
  const [startTime, setStartTime] = useState('18:00');
  const [endTime, setEndTime] = useState('21:00');
  const [eventDays, setEventDays] = useState([]);

  // Agenda (timeslots) state
  const [timeslots, setTimeslots] = useState([]);

  // Pricing state (pricing items)
  const [priceSlots, setPriceSlots] = useState([]);

  // Location state
  const [location, setLocation] = useState({
    venue: '',
    street: '',
    city: '',
    county: '',
    postcode: '',
    state: '',
    country: '',
    description: '',  // Moved description into location object
  });

  // Branding image and image file state
  const [imageUrl, setImageUrl] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState(''); 
  const [imageFile, setImageFile] = useState(null); 
  const [thumbnailFile, setThumbnailFile] = useState(null); 

  // Manage active tab state
  const [activeTab, setActiveTab] = useState('event');

  // Reset scroll position to top when active tab changes
  useEffect(() => {
  const contentContainer = document.querySelector('.create-event-content');
  if (contentContainer) {
    contentContainer.scrollTo(0, 0); // Reset scroll to top
  }
  }, [activeTab]); // Trigger this effect when activeTab changes


  // Function to add county if it doesn't exist
  const addCountyIfNotExists = async (county) => {
     const countyQuery = query(collection(db, 'counties'), where('name', '==', county));
     const querySnapshot = await getDocs(countyQuery);

    if (querySnapshot.empty) {
      // If county does not exist, add it
      await addDoc(collection(db, 'counties'), { name: county });
      console.log(`Added new county: ${county}`);
      // Select the new county for the user's county selection
      // INSERT CODE HERE
    } else {
      console.log(`County ${county} already exists.`);
    }
  };

  // Handle form submission to Firestore
  const handleCreateEvent = async () => {
    setDisableSubmit(true); // disable submit button, stop additional clicks

    const userId = localStorage.getItem("userId"); // Retrieve the userId from local storage
    const permission = localStorage.getItem("permission"); // Find if they have permission to skip event approval

    // Generate the custom event ID
    const randomSixDigit = Math.floor(100000 + Math.random() * 900000);
    const friendlyEventId = isRecurring
      ? `weekly_${selectedDay}_${eventName}_${randomSixDigit}`
      : `event_${eventDate}_${eventName}_${randomSixDigit}`;
  
      // Create an event timestamp
      const eventDateToUse = eventDate ? new Date(eventDate) : new Date();  // Use current date if eventDate is empty
      const startTimeToUse = startTime || "00:00";  // Use midnight if startTime is empty
      // Combine eventDate and startTime into a full timestamp
      const [hours, minutes] = startTimeToUse.split(':');
      const fullDateTime = new Date(eventDateToUse);
      fullDateTime.setHours(hours);
      fullDateTime.setMinutes(minutes);
      // Convert to Firebase Timestamp
      const firebaseTimestamp = Timestamp.fromDate(fullDateTime);

    const eventData = {
      creator: userId,
      approved: !!permission || false,
      eventName: eventName || '',
      shortLocation: shortLocation || '',
      description: description || '',
      danceTypes: danceTypes || [],
      isRecurring: isRecurring ?? true,
      startTime: startTime || null,
      endTime: endTime || null,
      timestamp: firebaseTimestamp,
      // isCancelled: false,
      location: {
        venue: location.venue || '',
        street: location.street || '',
        city: location.city || '',
        county: location.county || '',  // County is part of the location object
        postcode: location.postcode || '',
        state: location.state || '',
        country: location.country || '',
        description: location.description || '',
      },
    };
    // Only incldue the below if data is actually coded
    if (team) eventData.team = team;
    if (isRecurring) eventData.recurrenceDay = selectedDay;
    if (eventDate) eventData.eventDate = eventDate;
    if (eventEndDate) eventData.eventEndDate = eventEndDate;
    if (!isRecurring && eventEndDate && eventDays) eventData.eventDays = eventDays;
    if (endTime) eventData.endTime = endTime;
    if (timeslots && timeslots.length > 0) {
      eventData.agenda = timeslots.map(slot => ({
          id: slot.id,
          slotName: slot.slotName || '',
          startTime: slot.startTime || null,
          endTime: slot.endTime || null,
          ...(slot.levels && slot.levels.length > 0 && { levels: slot.levels }),
          ...(slot.date && {date: slot.date})
      }));
  }
  if (priceSlots && priceSlots.length > 0) {
      eventData.pricing = priceSlots.map(slot => ({
          id: slot.id,
          itemName: slot.itemName || '',
          prices: slot.prices,
      }));
  }
  

    // Validate the form data
    const errors = validateEvent(eventData, imageUrl);
    if (errors.length > 0) {
      setValidationErrors(errors);
      setIsModalOpen(true);  // Open the modal with the errors
      setDisableSubmit(false); // re-enable submit button
      return;
    }
  
    try {
      // Add event data to Firestore
      const docRef = await addDoc(collection(db, 'events'), { ...eventData, friendlyEventId });
      console.log('Document written with ID: ', friendlyEventId);

      // Add eventId to user's eventCreator list in Firestore and local storage
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, {
        eventCreator: arrayUnion(docRef.id) // Adds eventId to the user's eventCreator
      });
      // Retrieve existing eventCreator data from localStorage, or initialize as an empty array
      const existingEventIds = JSON.parse(localStorage.getItem('eventCreator')) || [];
      // Add the new event ID if it doesn't already exist in the array
      if (!existingEventIds.includes(docRef.id)) {
        existingEventIds.push(docRef.id);
      }
      // Save the updated array back to localStorage as JSON
      localStorage.setItem('eventCreator', JSON.stringify(existingEventIds));




      // New function for saving event images
      if (imageFile || thumbnailFile) {
      try {
        const storageUrls = await eventImageSave(docRef.id, null, { eventImage: imageFile, thumbnailImage: thumbnailFile });
        
        await updateDoc(docRef, { imageUrl: storageUrls.imageUrl });
        await updateDoc(docRef, { thumbnailUrl: storageUrls.thumbnailUrl });

      } catch (storageError) {
          console.error("Image upload failed:", storageError);
          // Handle the error, e.g., display a message to the user
          setDisableSubmit(false); // Re-enable submit
          return; // Stop the rest of the save operation
      }
      }



      // Add county if not already in the list
      if (shortLocation) {
        await addCountyIfNotExists(shortLocation);
      }

      // Add county to their selected locations if not already
      const userCounties = JSON.parse(localStorage.getItem('selectedCounties')) || [];
      if (!userCounties.includes(shortLocation))  userCounties.push(shortLocation);
      localStorage.setItem('selectedCounties', JSON.stringify(userCounties));

  

      navigate(`/view-event/${docRef.id}`, { state: { from: '/create-event' } });
    } catch (e) {
      setDisableSubmit(false); // re-enable submit button
      console.error('Error adding document or uploading image: ', e);
    }
  };

  // Define the footer buttons
  const footerButtons = [
    {
      component: (
        <Button
          variant="secondary"
          label="Cancel"
          icon={cancelIcon}
          onClick={() => 
          (description.length > 20 || timeslots.length > 0 || priceSlots.length > 0 ) ?
            setIsCancelChangesModalOpen(true) :
            navigate(-1)
          }
        />
      )
    },
    {
      component: (
        <Button
          variant="primary"
          label="Create"
          icon={tickIcon}
          onClick={handleCreateEvent}
          disabled={disableSubmit}
        />
      )
    }
  ];

  // Render the tab content
  const renderTabContent = () => {
    switch (activeTab) {
      case 'event':
        return (
          <Event
            danceTypes={danceTypes}
            setDanceTypes={setDanceTypes}
            isRecurring={isRecurring}
            setIsRecurring={setIsRecurring}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            eventDate={eventDate}
            setEventDate={setEventDate}
            eventEndDate={eventEndDate}
            setEventEndDate={setEventEndDate}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            eventDays={eventDays}
            setEventDays={setEventDays}
          />
        );
      case 'about':
        return (
          <About
            eventName={eventName}
            setEventName={setEventName}
            description={description}
            setDescription={setDescription}
            team={team}
            setTeam={setTeam}
          />
        );
      case 'agenda':
        return (
          <Agenda
            timeslots={timeslots}
            setTimeslots={setTimeslots}
            eventDays={eventDays}
            eventDate={eventDate}
          />
        );
      case 'pricing':
        return (
          <Pricing
            pricingItems={priceSlots}  // Correctly passing priceSlots as pricingItems
            setPricingItems={setPriceSlots}  // Correct setter function
          />
        );
      case 'location':
        return (
          <Location
            location={location}
            setLocation={setLocation}
            setShortLocation={setShortLocation}
          />
        );
      case 'branding':
        return (
          <Branding
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            setImageFile={setImageFile}  // New: Set the image file for upload later
            setThumbnailUrl={setThumbnailUrl}
            setThumbnailFile={setThumbnailFile}
          />
        );           
      default:
        return null;
    }
  };

  return (
    <div className="create-event-page">
      <PageHeader title="Create event" />
      <TopNav tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />
      <div className="create-event-content">
        {renderTabContent()}
      </div>
      <PageFooter buttons={footerButtons} />

      {/* SlideUpModal to show validation errors */}
      <SlideUpModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Validation Errors"
      >
        <ul>
          {validationErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </SlideUpModal>

      {/* SlideUpModal introduction to new event creators */}
      <SlideUpModal
        isOpen={isIntroModalOpen}
        onClose={() => setIsIntroModalOpen(false)}
        title="Welcome to creating an event"
        primaryActionLabel="I agree"
        onPrimaryAction={() => setIsIntroModalOpen(false)}
        primaryActionIcon={tickIcon}
      >
        <p>
          Thank you for using Find Your Rhythm to create your event. Before you begin, just a few house rules:
        </p>
        <ul>
          <li>No private events - events must be open to everyone</li>
          <li>Do not use innappropriate language or imagery, provide inaccurate information, or try to offend anyone.</li>
          <li>Please keep your event up to date. This app makes it easy to make updates, location changes, and even announcements.</li>
        </ul>
        <p>Events are subject to approval - this is a temporary measure to ensure your events meet our guidelines. Once approved, future events you create will not require approval. During approval, events may take up to 48 hours to appear.</p>
        <p>This app is currently in Beta release. Please report any issues or key features you require - find.your.rhythm@hotmail.com.</p>
      </SlideUpModal>

      {/* SlideUpModal preventing accidental click of 'Cancel' buton*/}
      <SlideUpModal
        isOpen={isCancelChangesModalOpen}
        onClose={() => setIsCancelChangesModalOpen(false)}
        title="Cancel changes"
        primaryActionLabel="Confirm"
        onPrimaryAction={() => navigate(-1)}
        primaryActionIcon={tickIcon}
      >
        <p>Are you sure you want to cancel creating this event?</p>
      </SlideUpModal>
    </div>
  );
};

export default CreateEventScreen;
