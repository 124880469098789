// src/pages/InitiationScreen.js
import React, { useEffect } from 'react';
import './InitiationScreen.css';
import logo from '../assets/logo.png';

const InitiationScreen = ({ onComplete }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onComplete();
    }, 3000); 

    return () => clearTimeout(timer); // Cleanup the timer
  }, [onComplete]);

  return (
     

    <div className="initiation-screen-container" onClick={onComplete}>
    <img src={logo} alt="Find Your Rhythm Logo" className="logo" />
    <h1 className="main-title">Find Your Rhythm</h1>
  </div>
  );
};

export default InitiationScreen;
