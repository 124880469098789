// src/components/view-event/Pricing.js
import React from 'react';

const Pricing = ({ pricing }) => {
  // Check if pricing data is available
  if (!pricing || pricing.length === 0) {
    return (
      <div className="layout">
        <div className='viewevent-container'>
          <p>No pricing information</p>
        </div>
      </div>
    );
  }

  // Extract all unique ticket types from pricing data
  const allTicketTypes = [
    ...new Set(
      pricing.flatMap(price => Object.keys(price.prices))
    ),
  ];

  return (
    <div className="layout">
      <div className='viewevent-container price'>
        <h3>Admission</h3>

        <div className='price-table'>
          {/* Table Header */}
          <div className='price-table-header'>
            <div className='price-item-name-header'></div>
            {allTicketTypes.map((ticketType, index) => (
              <div key={index} className='price-ticket-type-header'>{ticketType}</div>
            ))}
          </div>

          {/* Table Body */}
          {pricing.map((price, index) => (
            <div key={index} className='price-table-row'>
              <div className='price-item-name'>{price.itemName}</div>
              {allTicketTypes.map((ticketType, idx) => (
                <div key={idx} className='price-ticket-type'>
                  {price.prices[ticketType] !== undefined ? price.prices[ticketType] : '-'}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
