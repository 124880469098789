// src/components/setup/setupInstall.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button'; // Importing Button component
import iosShareIcon from '../../assets/icons/iosShareIcon.png'; // Import iOS share icon

const SetupInstall = ({ selectedDances, selectedCounties }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);
  const [installInitiated, setInstallInitiated] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const [isOther, setIsOther] = useState(false);

  const navigate = useNavigate();

  // Check if user has selected at least one dance type and county
  const isValidSelection = selectedDances.length > 0 && selectedCounties.length > 0;

  // useEffect for PWA installation checks
  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault(); // Prevent the default mini-info bar
      setDeferredPrompt(e); // Store the event
      setIsInstallable(true); // Allow installation
    };

    const checkPwaInstallation = () => {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        setIsInstalled(true);
        setIsInstallable(false); // No need to show install button
      } else {
        setIsInstalled(false);
      }
    };

    // Check if the device is iOS using Safari
    const isIosSafari = /iPhone|iPad/i.test(navigator.userAgent) && /Safari/.test(navigator.userAgent);
    setIsIos(isIosSafari);

    // Check if neither installable nor iOS (other devices)
    if (!isIosSafari && !deferredPrompt) {
      setIsOther(true);
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    checkPwaInstallation();

    const handleVisibilityChange = () => {
      checkPwaInstallation();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [deferredPrompt]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        setInstallInitiated(true);
      }
      setDeferredPrompt(null);
      setIsInstallable(false);
    }
  };

  if (!isValidSelection) {
    return (
      <p>Please select at least one dance type and one location before continuing.</p>
    );
  }

  if (isInstalled) {
    return (
        <div className='page-install'>
            <p>‘Find your Rhythm’ is already installed on your device.</p>
            <Button
                label="View dance events"
                onClick={() => navigate('/events')}
                variant="primary"
                />
        </div>
    );
  }

  if (isInstallable) {
    return (
      <div className='page-install'>
        <p>Great! Let’s get started!</p>
        <p>For the best experience, install ‘Find Your Rhythm’ to your phone - it’s free!</p>
          <Button
            label="Install"
            onClick={handleInstallClick}
            variant="primary"
          />
          <Button
          label="Continue in browser"
          onClick={() => navigate('/events')}
          variant="secondary"
        />
      </div>
  );}

  if (installInitiated) {
    return (
      <div className='page-install'>
          <p>Installing to your device.</p>
          <p>Once installed, ‘Find your Rhythm’ will appear as an app on your Home screen.</p>
        <Button
          label="Continue in browser"
          onClick={() => navigate('/events')}
          variant="secondary"
        />
        
      </div>
    );
  }

  if (isIos) {
    return (
      <div className='page-install'>
        <p>For the best experience, install ‘Find Your Rhythm’ to your phone - it’s free!</p>
        <p>Just tap <img src={iosShareIcon} alt="Share Icon" /> then 'Add to Home Screen’</p>
        <Button
          label="Continue without app"
          onClick={() => navigate('/events')}
          variant="secondary"
        />
      </div>
    );
  }

  if (isOther) {
    return (
      <div className='page-install'>
        <p>Great! Let’s get started!</p>
        {/* <p>For the best experience, install ‘Find Your Rhythm’ on a compatible Android or iOS device.</p> */}
        <Button
          label="View dance events"
          onClick={() => navigate('/events')}
          variant="primary"
        />
      </div>
    );
  }

  return null;
};

export default SetupInstall;
