// src/pages/ManageEvents.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../components/layout/PageHeader';
import Button from '../components/components/Button';
import ChangeLocation from '../components/menu/changeLocation';
import ChangeDance from '../components/menu/changeDance';
import LocationIcon from '../assets/icons/icon-location-white.png';
import DanceIcon from '../assets/icons/icon-dance-white.png';
import PlusIcon from '../assets/icons/icon-plus-white.png';
import eventStorage from '../components/events/eventStorage';
import EventCard from '../components/event-display/EventCard';
import './MyEvents.css';

const MyEvents = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // Control PageHeader modal
  const [isCountyModalOpen, setIsCountyModalOpen] = useState(false); // Control county modal
  const [isDanceModalOpen, setIsDanceModalOpen] = useState(false); // Control dance selection modal
  const [selectedCounties, setSelectedCounties] = useState([]); // Store selected counties
  const [selectedDances, setSelectedDances] = useState([]); // Store selected dances
  const [loading, setLoading] = useState(false); // Track loading state
  const [events, setEvents] = useState([]); // Store events

   // Load selected counties and dances from localStorage on component mount
   useEffect(() => {
    setTimeout(() => {
      const cachedCounties = localStorage.getItem('selectedCounties');
      if (cachedCounties) {
        setSelectedCounties(JSON.parse(cachedCounties));
      }

      const cachedDances = localStorage.getItem('selectedDances');
      if (cachedDances) {
        setSelectedDances(JSON.parse(cachedDances));
      }
    }, 1); // Delay by 1ms for reliable cache access
  }, []);

 // Modal content for PageHeader
 const modalContent = (
  <div className="layout">
    {/* <Button
      variant="primary"
      label="Create event"
      icon={PlusIcon}
      onClick={() => navigate('/create-event')}
    /> */}
    <Button
      variant="primary"
      label="Change location"
      icon={LocationIcon}
      onClick={() => {
        setIsModalOpen(false); // Close PageHeader modal
        setIsCountyModalOpen(true); // Open county modal
      }} 
    />
    <Button
      variant="primary"
      label="Change dance"
      icon={DanceIcon} // Use a dance icon
      onClick={() => {
        setIsModalOpen(false); // Close PageHeader modal
        setIsDanceModalOpen(true); // Open dance modal
      }} 
    />
  </div>
);

  // useEffect(() => {
  //   const fetchEvents = async () => {
  //     if (!userId) return; // Ensure userId is available
  //     setLoading(true);

  //     try {
  //       // Fetch the events where this user is the creator
  //       const filters = {
  //         creator: userId,
  //       };
  //       const fetchedEvents = await eventStorage({
  //         filters: filters,
  //         onlyDances: false,
  //         onlyCounties: false,
  //         eventSeries: true,
  //       });

  //       // Sort events by eventDate... recurring events don't have eventDate
  //       // const sortedEvents = fetchedEvents.sort((a, b) => {
  //       //     const dateA = new Date(a.eventDate);
  //       //     const dateB = new Date(b.eventDate);
  //       //     return dateA - dateB; // Ascending order
  //       // });

  //       setEvents(fetchedEvents); // Save fetched events to state


  //     } catch (error) {
  //       console.error('Failed to fetch events:', error);
  //     } finally {
  //       setLoading(false); // Stop loading spinner
  //     }
  //   };

  //   fetchEvents();
  // }, [userId]); // Refetch when userId changes


  return (
    <div className="manage-events-page">
      <PageHeader
        title="Favourites"
        modalContent={modalContent}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen} // Pass control of modal state
      />

      <div className="page-content grouped-eventcards">
        
          <p>Feature coming soon!</p>
      </div>

      {/* County selection modal */}
      <ChangeLocation
        isOpen={isCountyModalOpen}
        onClose={() => setIsCountyModalOpen(false)}
        selectedCounties={selectedCounties} // Pass selected counties
        setSelectedCounties={setSelectedCounties} // Update counties on close
      />

      {/* Dance selection modal */}
      <ChangeDance
        isOpen={isDanceModalOpen}
        onClose={() => setIsDanceModalOpen(false)}
        selectedDances={selectedDances} // Pass selected dances
        setSelectedDances={setSelectedDances} // Update dances on close
      />
    </div>
  );
};

export default MyEvents;
