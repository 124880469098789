// src/components/new-event/About.js
import React from 'react';
import Input from '../components/Input';

const About = ({ eventName, setEventName, description, setDescription, team, setTeam }) => {
  return (
    <div className="layout">
      <h2>Event name</h2>
      <Input
        type="text"
        placeholder="My event"
        value={eventName}
        onChange={(e) => setEventName(e.target.value)}
      />

      <h2>Description</h2>
      <Input
        type="description"
        placeholder="What’s the event about?&#10;Who’s it for?&#10;Dress code?"
        value={description}
        multiline={true}
        showIcon={false} 
        onChange={(e) => setDescription(e.target.value)}
      />

<h2>Team</h2>
      <Input
        type="text"
        placeholder="Who’s running the event?"
        value={team}
        onChange={(e) => setTeam(e.target.value)}
      />
    </div>
  );
};

export default About;
