// src/pages/WeeklyEvents.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './WeeklyEvents.css';
import PageHeader from '../components/layout/PageHeader';
import PlusIcon from '../assets/icons/icon-plus-white.png';
import LocationIcon from '../assets/icons/icon-location-white.png';
import DanceIcon from '../assets/icons/icon-dance-white.png';
import Button from '../components/components/Button';
import Tag from '../components/components/Tag';
import ChangeLocation from '../components/menu/changeLocation';
import ChangeDance from '../components/menu/changeDance';
import LoaderSmall from '../assets/loader-small.svg';
import EventCard from '../components/event-display/EventCard';
import PlaceholderNoEvent from '../components/event-display/EventCard-NoEvent';
import eventStorage from '../components/events/eventStorage';
import { format, addDays } from 'date-fns';

const WEEK_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const WeeklyEvents = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // Control PageHeader modal
  const [isCountyModalOpen, setIsCountyModalOpen] = useState(false); // Control county modal
  const [isDanceModalOpen, setIsDanceModalOpen] = useState(false); // Control dance selection modal
  const [selectedCounties, setSelectedCounties] = useState([]); // Store selected counties
  const [selectedDances, setSelectedDances] = useState([]); // Store selected dances
  const [eventsByDay, setEventsByDay] = useState({});
  const [loadingDays, setLoadingDays] = useState({});

  // Update events whenever dance/location modal is closed
  const handleModalClose = (setModalOpen) => {
    setModalOpen(false);
    // eventFetch(); // Call the eventFetch function here
  };

  // Initialize days of the week starting from today
  const getWeekDays = () => {
    const today = new Date();
    return Array.from({ length: 7 }, (_, i) => {
      const date = addDays(today, i);
      return {
        dayName: i === 0 ? 'Today' : WEEK_DAYS[date.getDay()], // Replace current day with "Today"
        dayNumber: date.getDay(),
        dateString: format(date, 'yyyy-MM-dd')
      };
    });
  };

  const weekDays = getWeekDays();

 // Fetch events for each day of the week if both selected dances and counties are present
useEffect(() => {
  setTimeout(() => {
  // Only proceed if there is at least one dance and one county selected
  if (selectedDances.length > 0 && selectedCounties.length > 0) {
    const fetchEventsForDay = async (dayNumber, dateString) => {
      setLoadingDays((prev) => ({ ...prev, [dayNumber]: true }));

      // Fetch both recurring and one-off events
      const filtersrecurring = {
        recurrenceDay: dayNumber,
        // approved: true
      };
      const filtersoneoff = {
        // eventDate: dateString,
        eventDays: dateString, // Return one-off events if they run over this day
        isRecurring: false,
        // approved: true
      };
      const recurringEvents = await eventStorage({
        filters: filtersrecurring,
        onlyDances: true,
        onlyCounties: true,
        occurrenceDate: dateString,
      });
      const oneOffEvents = await eventStorage({
        filters: filtersoneoff,
        onlyDances: true,
        onlyCounties: true,
        occurrenceDate: dateString, 
        // eventSeries: true,
      });

      const filteredEvents = [...recurringEvents, ...oneOffEvents];
      
        // Combine only events that match the specific day or date
      // const filteredEvents = [...recurringEvents, ...oneOffEvents].filter((event) =>
      //   event.isRecurring ? event.recurrenceDay === dayNumber : event.eventDate === dateString
      // );

      

      // Update state with the filtered events for the specific day
      setEventsByDay((prev) => ({ ...prev, [dayNumber]: filteredEvents }));
      setLoadingDays((prev) => ({ ...prev, [dayNumber]: false }));
    };

    weekDays.forEach(({ dayNumber, dateString }) => {
      fetchEventsForDay(dayNumber, dateString);
    });
  }
}, 1);  // Delay by 1ms for reliable cache access
}, [selectedDances, selectedCounties]); // Refetch when filters or weekDays change


  // Load selected counties and dances from localStorage on component mount
  useEffect(() => {
    setTimeout(() => {
      const cachedCounties = localStorage.getItem('selectedCounties');
      if (cachedCounties) {
        setSelectedCounties(JSON.parse(cachedCounties));
      }

      const cachedDances = localStorage.getItem('selectedDances');
      if (cachedDances) {
        setSelectedDances(JSON.parse(cachedDances));
      }
    }, 1); // Delay by 1ms for reliable cache access
  }, []);


  // Modal content for PageHeader
  const modalContent = (
    <div className="layout">
      {/* <Button
        variant="primary"
        label="Create event"
        icon={PlusIcon}
        onClick={() => navigate('/create-event')}
      /> */}
      <Button
        variant="primary"
        label="Change location"
        icon={LocationIcon}
        onClick={() => {
          setIsModalOpen(false); // Close PageHeader modal
          setIsCountyModalOpen(true); // Open county modal
        }} 
      />
      <Button
        variant="primary"
        label="Change dance"
        icon={DanceIcon} // Use a dance icon
        onClick={() => {
          setIsModalOpen(false); // Close PageHeader modal
          setIsDanceModalOpen(true); // Open dance modal
        }} 
      />
    </div>
  );

  return (
    <div className="weekly-events-page">
      <PageHeader 
        title="This week" 
        modalContent={modalContent} 
        isModalOpen={isModalOpen} 
        setIsModalOpen={setIsModalOpen} // Pass control of modal state
      />
       
      <div className="page-content">
        {/* Display selected counties as tags */}
        <div className="filter-tags">
          {selectedCounties.map((county, index) => (
            <Tag 
              key={index} 
              label={county} 
              dismissible={false} 
              onClick={() => setIsCountyModalOpen(true)} // Open county modal
            />
          ))}
        </div>

        {weekDays.map(({ dayName, dayNumber, dateString }) => (
          <div key={dayNumber} className="grouped-eventcards">
            <h2>{dayName}</h2>
            {/* <p>{dateString}</p> */}
            {loadingDays[dayNumber] ? (
              <img src={LoaderSmall} style={{ width: "24px" }} alt="Loading..." />
            ) : eventsByDay[dayNumber]?.length > 0 ? (
              eventsByDay[dayNumber].map((event) => (
                <EventCard
                  key={event.eventId}
                  event={event}
                  onClick={() =>
                    navigate(`/view-event/${event.eventId}?date=${event.occurrenceDate}`, { state: { from: '/events' } })
                  }
                />
              ))
            ) : (
              <PlaceholderNoEvent />
            )}
          </div>
        ))}
      </div>


      {/* County selection modal */}
      <ChangeLocation
        isOpen={isCountyModalOpen}
        onClose={() => setIsCountyModalOpen(false)}
        selectedCounties={selectedCounties} // Pass selected counties
        setSelectedCounties={setSelectedCounties} // Update counties on close
      />

      {/* Dance selection modal */}
      <ChangeDance
        isOpen={isDanceModalOpen}
        onClose={() => setIsDanceModalOpen(false)}
        selectedDances={selectedDances} // Pass selected dances
        setSelectedDances={setSelectedDances} // Update dances on close
      />

    </div>
  );
};

export default WeeklyEvents;
