// src/pages/ManageEvents.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../components/layout/PageHeader';
import Button from '../components/components/Button';
import PlusIcon from '../assets/icons/icon-plus-white.png';
import eventStorage from '../components/events/eventStorage';
import EventCard from '../components/event-display/EventCard';
import './ManageEvents.css';

const ManageEvents = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // Control PageHeader modal
  const [loading, setLoading] = useState(false); // Track loading state
  const [events, setEvents] = useState([]); // Store events
  const userId = localStorage.getItem('userId');

  // Modal content for PageHeader
  const modalContent = (
    <div className="layout">
      <Button
        variant="primary"
        label="Create event"
        icon={PlusIcon}
        onClick={() => navigate('/create-event')}
      />
    </div>
  );

  useEffect(() => {
    const fetchEvents = async () => {
      if (!userId) return; // Ensure userId is available
      setLoading(true);

      try {
        // Fetch the events where this user is the creator
        const filters = {
          creator: userId,
        };
        const fetchedEvents = await eventStorage({
          filters: filters,
          onlyDances: false,
          onlyCounties: false,
          eventSeries: true,
          onlyApproved: false,
        });

        // Sort events by eventDate... recurring events don't have eventDate
        // const sortedEvents = fetchedEvents.sort((a, b) => {
        //     const dateA = new Date(a.eventDate);
        //     const dateB = new Date(b.eventDate);
        //     return dateA - dateB; // Ascending order
        // });

        setEvents(fetchedEvents); // Save fetched events to state


      } catch (error) {
        console.error('Failed to fetch events:', error);
      } finally {
        setLoading(false); // Stop loading spinner
      }
    };

    fetchEvents();
  }, [userId]); // Refetch when userId changes


  return (
    <div className="manage-events-page">
      <PageHeader
        title="Manage events"
        modalContent={modalContent}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen} // Pass control of modal state
        showBackButton
        onBackClick={() => navigate(-1)}
      />

      <div className="page-content grouped-eventcards">
        {loading ? (
          <p>Loading events...</p>
        ) : events.length > 0 ? (
          events.map((event) => (
            <EventCard
              key={event.eventId}
              event={event}
              series={true}
              onClick={() =>
                navigate(`/view-event/${event.eventId}?date=${event.occurrenceDate}`, {
                  state: { from: '/manageevents' },
                })
              }
              onSeriesClick={(date) =>
                navigate(`/view-event/${event.eventId}?date=${date}`, {
                  state: { from: '/events' },
                })
              }
            />
          ))
        ) : (
          <div className="layout">
            <p>You don't currently have any events. Click "Create event" to get started!</p>
            <Button
              variant="primary"
              label="Create event"
              icon={PlusIcon}
              onClick={() => navigate('/create-event')}
            />
            </div>
        )}
      </div>
    </div>
  );
};

export default ManageEvents;
