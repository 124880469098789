// src/components/RadioButton.js
import React, { useState, useEffect } from 'react';
import './RadioButton.css';

const RadioButton = ({ labels = [], selectedLabel = '', onSelectionChange }) => {
  // Track the currently selected radio button
  const [selected, setSelected] = useState(selectedLabel);

  // Update selected state when selectedLabel prop changes (e.g., when modal is reopened)
  useEffect(() => {
    setSelected(selectedLabel);
  }, [selectedLabel]);

  const handleRadioChange = (label) => {
    setSelected(label);
    onSelectionChange(label);
  };

  return (
    <div className="radio-list">
      {labels.map((label, index) => (
        <label key={index} className="radio-container">
          <input
            type="radio"
            checked={selected === label}
            onChange={() => handleRadioChange(label)}
          />
          <span className="custom-radio">
            {selected === label && <span className="inner-circle" />}
          </span>
          <span className="radio-label">{label}</span>
        </label>
      ))}
    </div>
  );
};

export default RadioButton;
