// src/components/user/ServiceAgreement.js
import React from 'react';

const ServiceAgreement = () => (
  <div className='page-content textBlock'>
    <p><strong>Last updated: 29/10/2024</strong></p>

<p>Welcome to Find Your Rhythm! This app provides a platform for discovering and sharing dance events. Please review the following Terms of Service and Privacy Policy carefully, as they outline important guidelines and policies related to your use of the app.</p>

<h2>1. General Information</h2>
<p><strong>Creator:</strong> Find Your Rhythm is created and maintained by an independent developer and is not affiliated with any organisation or entity.</p>
<p><strong>Availability:</strong> The app is available as a web app and is intended to be used as a Progressive Web App (PWA).</p>
<p><strong>Disclaimer:</strong> This app is currently in beta. While efforts are made to ensure a positive user experience, bugs or other issues may arise. The creator holds no responsibility for any errors, data loss, or issues that may result from using the app.</p>

<h2>2. Data Collection and Storage</h2>
<p><strong>Event Data:</strong> Find Your Rhythm stores event-related information (such as event name, date, location, and dance type) in Google Firebase Firestore. This data is available to all users of the app and is publicly visible.</p>
<p><strong>User ID:</strong> If you create an event, your user ID (provided by Firebase Authentication) will be associated with that event. This user ID is not visible to other users.</p>
<p><strong>Authentication:</strong> The app uses Firebase Authentication to allow users to create accounts and log in. No personal data, beyond Firebase's anonymous user ID, is saved by Find Your Rhythm.</p>
<p><strong>User Preferences:</strong></p>
<ul>
  <li><strong>Non-Logged-In Users:</strong> Selected dance types, locations (event filters), and favourite events are saved locally in your device’s cache.</li>
  <li><strong>Logged-In Users:</strong> Selected dance types, locations, and favourite events may be saved to Firebase to allow access across devices. No personal data is saved.</li>
</ul>

<h2>3. User Conduct</h2>
<p><strong>Event Content:</strong> By creating an event, you agree that all information provided is accurate, truthful, and free of any offensive, harmful, or inappropriate content. Do not post anything that you do not wish to be publicly visible, as event information is accessible to all users of the app.</p>
<p><strong>Prohibited Actions:</strong> The creation of false, misleading, or offensive events is strictly prohibited. Users who violate these terms may be restricted from creating events or may be blocked from using the app.</p>

<h2>4. Liability Disclaimer</h2>
<p>Find Your Rhythm is provided "as is" without warranties of any kind. The creator is not liable for any damages or issues arising from your use of the app, including but not limited to data loss, service disruptions, or any bugs within the app.</p>

<h2>5. Privacy and Data Security</h2>
<p><strong>No Personal Data Collection:</strong> Find Your Rhythm does not collect or store personal data beyond basic Firebase Authentication data (user ID) needed for event creation. The app does not use any information for analytics, marketing, or any other purpose.</p>
<p><strong>Data Security:</strong> While Firebase provides security for data, no system is perfectly secure. Users are advised to use the app responsibly and avoid sharing any sensitive information through event listings.</p>

<h2>6. Changes to Terms and Privacy Policy</h2>
<p>The creator reserves the right to update these Terms of Service and Privacy Policy at any time. Users will be notified of significant changes, and continued use of the app constitutes acceptance of any revised terms.</p>

<h2>7. Contact</h2>
<p>For questions or concerns, please contact find.your.rhythm@hotmail.com</p>

<p>By using Find Your Rhythm, you agree to these Terms of Service and Privacy Policy. Thank you for supporting this independent app and respecting its community guidelines.</p>
  </div>
);

export default ServiceAgreement;
