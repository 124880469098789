// src/components/events/eventStorage.js
// This component fetches the relevant events from indexed db, sends them to eventExceptions to get the required instance of that event, then returns these events
import { openDB } from "idb";
import eventFetch from "./eventFetch";
import applyEventExceptions from "./eventExceptions";
const eventValidTime = (10) * 60 * 1000; // Fetch events if date of last fetch is older than this - in brackets is minutes

let isFetching = false;

const getDB = async () => {
  return await openDB("EventDB", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("events")) { // Create indexed db if it doesn't exist
        const store = db.createObjectStore("events", { keyPath: "eventId" });
        store.createIndex("recurrenceDay", "recurrenceDay", { unique: false });
        store.createIndex("eventDate", "eventDate", { unique: false });
        store.createIndex("danceTypes", "danceTypes", { unique: false });
        store.createIndex("shortLocation", "shortLocation", { unique: false });
      }
    },
  });
};

const isTimestampValid = () => {
  const timestamp = localStorage.getItem("dataTimestamp");
  if (!timestamp) return false;
  const timestampDate = new Date(timestamp);
  return (new Date() - timestampDate) < eventValidTime;
};

const isFiltersChanged = () => {
  const danceFilterChanged = localStorage.getItem("selectedDancesOutdated") || false;
  const countyFilterChanged = localStorage.getItem("selectedCountiesOutdated") || false;
  // console.log("danceFilterChanged ", danceFilterChanged);
  // console.log("countyFilterChanged ", countyFilterChanged);

  return (danceFilterChanged === 'true') || (countyFilterChanged === 'true') || false;  // convert strings to boolean
};

const fetchEvents = async (filters) => {
  const db = await getDB();
  const tx = db.transaction("events", "readonly");
  const store = tx.objectStore("events");

  // If no filters, get all events
  if (!filters || Object.keys(filters).length === 0) {
    console.log("No event filters provided");
    return await store.getAll();
  }

  // Use a cursor to apply filters
  const filteredEvents = [];
  await store.openCursor().then(function iterate(cursor) {
    if (!cursor) return;

    const matches = Object.entries(filters).every(([key, value]) => {
      const eventValue = key.includes(".") // Handle nested fields
        ? key.split(".").reduce((o, k) => o?.[k], cursor.value)
        : cursor.value[key];
      return Array.isArray(value) ? value.includes(eventValue) : eventValue === value;
    });

    if (matches) {
      filteredEvents.push(cursor.value);
    }

    return cursor.continue().then(iterate);
  });

  return filteredEvents;
};


const ensureUpToDateEvents = async () => {
  
// Could add an extra check here for internet connection - if (nointernet){return}

  if (isTimestampValid() && !isFiltersChanged()) {
    console.log("Timestamp is valid and filters haven't changed, no need to fetch new data.");
    return;
  }

  if (isFetching) {
    console.log("Fetch already in progress; waiting...");
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (isTimestampValid()) {
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  }  

  try {
    isFetching = true;
    console.log("Fetching new data from Firebase...");
    await eventFetch();
    console.log("Fetch complete.");
  } catch (error) {
    console.error("Error fetching events:", error);
  } finally {
    isFetching = false;
  }
};

export const eventStorage = async ({
  filters = {}, // Filters to apply to ensure only the necessary events are returned
  onlyDances = false, // Return events only for the filtered dances
  onlyCounties = false, // Return events only for the filtered counties
  onlyApproved = null, // Only return events which have been approved (null = auto mode)
  occurrenceDate = null, // The date of the event, this will copy any exceptions for that date into the main event data
  eventSeries = false, // Used to return all occurrences of an event instead of a single exception
} = {}) => {
  const selectedDances = JSON.parse(localStorage.getItem("selectedDances") || "[]");
  const selectedCounties = JSON.parse(localStorage.getItem("selectedCounties") || "[]");
  const userId = localStorage.getItem("userId") || 0;

  if (!selectedDances || !selectedCounties) {
    console.log("No cached dances or counties found in localStorage.");
    return [];
  }

  await ensureUpToDateEvents();

  let events = await fetchEvents();

  if (events.length === 0) {
    console.log("No data available after fetching. Returning empty array.");
    return [];
  }

  events = events.filter((event) => {
    return Object.entries(filters).every(([key, value]) => {
      if (key === 'eventDays') { // eventDays is an array, so use 'includes'
        return Array.isArray(event.eventDays) && event.eventDays.includes(value);
      } 
      else if (key === 'month') { // months should return all events with an eventDate within that month
        const eventMonth = event.eventDate?.slice(0, 7); // Extract yyyy-mm from eventDate
        const eventEndMonth = event.eventEndDate?.slice(0, 7);
        return (
          eventMonth === value || eventEndMonth === value  // Return events if they overrun multiple months
          // (eventMonth !== value && eventEndMonth === value ) // Return events if they overrun multiple months
        ); }
      else {
        return Array.isArray(value) ? value.includes(event[key]) : event[key] === value;
      }
    });
  });


  if (onlyDances && selectedDances.length > 0) {
    events = events.filter((event) =>
      event.danceTypes?.some((dance) => selectedDances.includes(dance))
    );
  }

  if (onlyCounties && selectedCounties.length > 0) {
    events = events.filter((event) => selectedCounties.includes(event.shortLocation));
  }

  // Filter out any that are not approved which aren't the current user's events
  if (onlyApproved !== false) {
    events = events.filter((event) => {
      return event.approved === true || event.creator === userId;
    });
  }
  

  // Use EventExceptions for exception management
  if (!eventSeries){
  events = events.map((event) => applyEventExceptions(event, occurrenceDate));
  }

  console.log("Final filtered and updated events:", events);
  return events;
};

export default eventStorage;
