// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { initializeFirestore, persistentLocalCache } from "firebase/firestore";
import { getStorage } from 'firebase/storage'; // Import storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDQMPwPkCr-DQJcQySgFvZCf1Vp_ascV6A",
  authDomain: "findyourrhythm1.firebaseapp.com",
  projectId: "findyourrhythm1",
  storageBucket: "findyourrhythm1.appspot.com",
  messagingSenderId: "199994940959",
  appId: "1:199994940959:web:b7b330511b1e932e5e3f2a",
  measurementId: "G-8N2PK15GNP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);

// Initialize Firestore with persistence options
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache({ synchronizeTabs: true }),
});

// Initialize Storage and export it
export const storage = getStorage(app); // Add storage initialization and export

// Export firebaseConfig as default, for compatibility with existing imports
export default firebaseConfig;
