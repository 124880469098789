// src/components/event-display/EventCard-NoEvent.js
import React, { useState, useEffect } from 'react';
import './EventCard.css';
import placeholderNoEvent from '../../assets/icons/placeholder-noevents.png';

const PlaceholderNoEvent = ({ }) => {

  return (
    <div >
      <div className="event-content noevent">
        {/* Image column */}
        <div className="event-image noevent">
          <img
            src={placeholderNoEvent}
            alt={'No events'}
          />
        </div>

        {/* Details column */}
        <div className="event-details">
          <h2 className="event-name">{'No events'}</h2>

          <div className="event-location">
            {/* <span>{'No events have been added for this day'}</span> */}
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default PlaceholderNoEvent;
