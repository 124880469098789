// src/pages/InitialSetup.js
// A welcome to the app, setup dance types and location, and install as PWA if available
// Only shows this if no dance/location data is cached
import React, { useState, useRef, useEffect } from 'react';
import './InitialSetup.css';
import SelectionCard from '../components/components/SelectionCard';
import { listOfDanceTypes } from '../data/danceTypes';
import { getFirestore, collection, getDocs } from 'firebase/firestore'; // Import Firebase functions
import { useNavigate } from 'react-router-dom'; // To navigate to the main page
import SetupInstall from '../components/setup/setupInstall';
import OurMission from '../components/setup/OurMission';

const InitialSetup = () => {
  const [currentPage, setCurrentPage] = useState(0); // Start at the first page
  const startXRef = useRef(null); // Swipe detection reference

  // State for selected dances and counties
  const [selectedDances, setSelectedDances] = useState([]);
  const [selectedCounties, setSelectedCounties] = useState([]);
  const [counties, setCounties] = useState([]);

  const navigate = useNavigate(); // For navigation

  // Fetch counties from Firestore
  useEffect(() => {
    const fetchCounties = async () => {
      const db = getFirestore();
      const countiesSnapshot = await getDocs(collection(db, 'counties'));
      const countiesList = countiesSnapshot.docs.map(doc => doc.data().name);
      setCounties(countiesList);
    };
    fetchCounties();
  }, []);

  const handlePageInteraction = (e) => {
    const target = e.target;
  
    // If the click is within a selection card, don't change the page
    if (target.classList.contains('selection-card') || target.classList.contains('selection-card-text')) {
      return;
    }
  
    if (currentPage < 3) {
      setCurrentPage(currentPage + 1);
    }
  };
  

  const handleDotClick = (index) => {
    setCurrentPage(index);
  };

  const handleTouchStart = (e) => {
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    const difference = startXRef.current - endX;

    if (difference > 50 && currentPage < 3) {
      setCurrentPage(currentPage + 1);
    } else if (difference < -50 && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  
  // Cache the selected dance types
  const handleDanceSelectionChange = (selectedItems) => {
    setSelectedDances(selectedItems);
    localStorage.setItem('selectedDances', JSON.stringify(selectedItems)); // Cache selection
  };

  // Cache the selected counties
  const handleCountySelectionChange = (selectedItems) => {
    setSelectedCounties(selectedItems);
    localStorage.setItem('selectedCounties', JSON.stringify(selectedItems)); // Cache selection
  };

  // Load cached selections on mount
  useEffect(() => {
    const cachedDances = JSON.parse(localStorage.getItem('selectedDances'));
    const cachedCounties = JSON.parse(localStorage.getItem('selectedCounties'));
    if (cachedDances) setSelectedDances(cachedDances);
    if (cachedCounties) setSelectedCounties(cachedCounties);
  }, []);

  return (
    <div className="setuppages">
      <div
        className="pages-container"
        onClick={handlePageInteraction}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        style={{ transform: `translateX(-${currentPage * 100}vw)` }}
      >
        <div className="page">
          <h2 className="text-subheader styledText">Our mission</h2>
          <OurMission/>
        </div>
        <div className="page">
          <h2 className="text-subheader">Dance?</h2>
          <p>Select all that apply</p>
          <SelectionCard
            items={listOfDanceTypes}
            layout="two-column" // 2-column layout
            selectedItems={selectedDances}
            onSelectionChange={handleDanceSelectionChange}
          />
        </div>
        <div className="page">
          <h2 className="text-subheader">Where?</h2>
          <p>Select all that apply</p>
          <SelectionCard
            items={counties}
            layout="single" // 1-column layout
            selectedItems={selectedCounties}
            onSelectionChange={handleCountySelectionChange}
          />
          <p><small>(More locations coming soon!)</small></p>
        </div>
        <div className="page">
          <h2 className="text-subheader">Ready?</h2>
          <SetupInstall
            selectedDances={selectedDances}
            selectedCounties={selectedCounties}
            />
        </div>
      </div>
      <div className="dots">
        {[...Array(4)].map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentPage ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default InitialSetup;
