// src/components/BottomNav.js
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './BottomNav.css';

// Import your custom icons
import WeeklyIcon from '../../assets/icons/nav_weekly.svg';
import EventsIcon from '../../assets/icons/nav_events.svg';
import FavouritesIcon from '../../assets/icons/nav_favourites.svg';
import UserIcon from '../../assets/icons/nav_user.svg';

const BottomNav = () => {
  const location = useLocation();
  return (
    <nav className="bottom-nav">
      <NavLink // for Events tab, highlight if either '/' or '/events'
        to="/events" 
        className={`nav-item ${location.pathname === '/' || location.pathname === '/events' ? 'active' : ''}`}>
        <img src={WeeklyIcon} alt="Weekly events" className="nav-icon" />
      </NavLink>
      <NavLink to="/calendar" className={({ isActive }) => `nav-item ${isActive ? 'active' : ''}`}>
        <img src={EventsIcon} alt="Calendar events" className="nav-icon" />
      </NavLink>
      <NavLink to="/myevents" className={({ isActive }) => `nav-item ${isActive ? 'active' : ''}`}>
        <img src={FavouritesIcon} alt="My events" className="nav-icon" />
      </NavLink>
      <NavLink // for User tab, highlight if either 'User' or '/manageevents'
        to="/profile" 
        className={`nav-item ${location.pathname === '/profile' || location.pathname === '/manageevents' ? 'active' : ''}`}>
        <img src={UserIcon} alt="User" className="nav-icon" />
      </NavLink>
    </nav>
  );
};

export default BottomNav;
