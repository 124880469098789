// src/components/PageHeader.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import './PageHeader.css';
import OptionsButton from './ButtonOptions';
import SlideUpModal from '../components/SlideUpModal';
import backIcon from '../../assets/icons/icon-previous-primary.png';

const PageHeader = ({
  title = 'Page title',
  modalContent = null,
  isModalOpen,
  setIsModalOpen,
  showBackButton = false,
  onBackClick = null,
}) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      navigate(-1); // Default back navigation
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <header className="header">
        <div className="header-content">
          {showBackButton && (
            <button className="back-button" onClick={handleBackClick}>
              <img src={backIcon} alt="Back" />
            </button>
          )}
          <h1 className="page-title">{title}</h1>
          {modalContent && <OptionsButton onClick={handleOpenModal} />}
        </div>
        <div className="header-extra">
          {/* Optional additional content goes here */}
        </div>
      </header>

      {/* Slide-up modal */}
      <SlideUpModal isOpen={isModalOpen} onClose={handleCloseModal} title="Options">
        {modalContent}
      </SlideUpModal>
    </>
  );
};

export default PageHeader;
