// src/components/event-display/EventSeries.js
// Takes in the event day and exceptions, returns UI of scrollable list of event dates
import React, { useState } from 'react';
import { format, addDays, subDays, startOfToday, getDay } from 'date-fns';
import iconPuzzle from '../../assets/icons/icon-puzzle.png';
import './EventSeries.css';
import './EventCard';

const EventSeries = ({ exceptions = [], recurrenceDay, eventId, onSeriesClick }) => {
  // const [viewPastEvents, setViewPastEvents] = useState(false);

  // Get the next occurrence of the specified weekday
  const getNextOccurrence = (weekday) => {
    const today = startOfToday();
    const dayDiff = (weekday - getDay(today) + 7) % 7; // Calculate days until the next occurrence
    return addDays(today, dayDiff);
  };

  // Generate up to 10 future dates for the given weekday
  const generateDatesFuture = (weekday, count = 10) => {
    const nextOccurrence = getNextOccurrence(weekday);
    const dates = [];
    for (let i = 0; i < count; i++) {
      dates.push(addDays(nextOccurrence, i * 7)); // Add 7 days for each subsequent week
    }
    return dates;
  };

  // // Add this later - a button can trigger showing past events
  // const generateDatesPast = (weekday, count = 2) => {
  //   const nextOccurrence = getNextOccurrence(weekday);
  //   const dates = [];
  //   for (let i = 0; i < count; i++) {
  //     dates.push(subDays(nextOccurrence, i * 7)); // Add 7 days for each subsequent week
  //   }
  //   const lastWeekEvent = [dates[1]];
  //   return lastWeekEvent; // Only return the last event to avoid overlap
  //   // I could write this so much better...
  // };
  
  const eventDates = generateDatesFuture(recurrenceDay);

  return (
    <div className="event-series">
      <div className="event-series-scroll">
        {eventDates.map((date) => {
          const formattedDate = format(date, 'EEE do MMM yy'); // e.g., "Mon 1st Jan 24"
          const isoDate = format(date, 'yyyy-MM-dd'); // ISO date format for navigation
          const isException = exceptions.some((ex) => ex.date === isoDate);
          const isCancelled = isException & exceptions.some((ex) => ex.isCancelled === true);

          return (
            <div
              key={isoDate}
              className="event-card event-card-occurrence"
              onClick={(e) => {
                e.stopPropagation(); // Very important!
                onSeriesClick(isoDate);
              }}
            >
              {/* {isException && ( // Descoped
                <img
                  src={iconPuzzle}
                  alt="Exception"
                  className="exceptionIcon"
                />
              )} */}
              <div className={`event-time ${isCancelled ? `textCancelled` : ''}`}>{formattedDate}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EventSeries;

