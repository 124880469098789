// src/components/new-event/children/Timeslot.js
import React, { useState, useEffect } from 'react';
import SubHeader from '../../layout/SubHeader';
import SlideUpModal from '../../components/SlideUpModal';
import Input from '../../components/Input';
import AddItem from '../../components/AddItem';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import Tag from '../../components/Tag';
import './Timeslot.css';

const Timeslot = ({ slotNumber, onDelete, onCopy, onUpdate, initialData }) => {
    const [closeOptionsModal, setCloseOptionsModal] = useState(false);

    const [slotName, setSlotName] = useState(initialData?.slotName || '');
    const [startTime, setStartTime] = useState(initialData?.startTime || '');
    const [endTime, setEndTime] = useState(initialData?.endTime || '');
    const [classLevels, setClassLevels] = useState(initialData?.levels || []);
    const [date, setDate] = useState(initialData?.date || '');
    const [showClassModal, setShowClassModal] = useState(false);

    const classOptions = ['Beginner', 'Improver', 'Intermediate', 'Advanced', 'Open level'];

    useEffect(() => {
        const updateTimeout = setTimeout(() => {
            onUpdate({
                id: slotNumber,
                slotName,
                startTime,
                endTime,
                levels: classLevels,
                date,
            });
        }, 300);

        return () => clearTimeout(updateTimeout);
    }, [slotName, startTime, endTime, classLevels, date, slotNumber, onUpdate]);

    const handleClassSelection = (selectedLevels) => {
        setClassLevels(selectedLevels);
    };

  const handleAddClass = () => setShowClassModal(true);
  const handleCloseClassModal = () => setShowClassModal(false);

    return (
        <div className="timeslot-card layout">
            <SubHeader
                title={`Slot ${slotNumber}`}
                modalContent={
                    <>
                        <Button 
                            label="Delete" 
                            variant="primary" 
                            onClick={onDelete} 
                        />
                        <Button 
                            label="Copy" 
                            variant="primary" 
                            onClick={() => {
                                onCopy();
                                setCloseOptionsModal(true);
                              }}  
                            onCloseModal={closeOptionsModal}
                        />
                    </>
                }
            />
            <Input
                type="text"
                placeholder="Lesson name"
                value={slotName}
                onChange={(e) => setSlotName(e.target.value)}
            />
            {/* <Input  // Could allow user to edit date
                type="date"
                label="Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
            /> */}
            <div className="time-inputs">
                <Input
                    label="Start Time"
                    type="time"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                />
                <p>to</p>
                <Input
                    label="End Time"
                    type="time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                />
            </div>
            <div className="class-levels">
                <div className="tag-container">
                    {classLevels.map((level, index) => (
                        <Tag
                            key={index}
                            label={level}
                            dismissible
                            onClick={() => handleClassSelection(classLevels.filter((l) => l !== level))}
                        />
                    ))}
                </div>
        <AddItem text="Classes (optional)" onClick={handleAddClass} />
            </div>
            <SlideUpModal
                isOpen={showClassModal}
                onClose={handleCloseClassModal}
                title="Class Levels"
                primaryActionLabel="Done"
                onPrimaryAction={handleCloseClassModal}
            >
                <div className="checkbox-list">
                    <Checkbox
                        labels={classOptions}
                        selectedLabels={classLevels}
                        onSelectionChange={handleClassSelection}
                    />
                </div>
            </SlideUpModal>
        </div>
    );
};

export default Timeslot;
