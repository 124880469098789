// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebaseConfig'; // Import Firebase auth
import eventFetch from './components/events/eventFetch';

import WeeklyEvents from './pages/WeeklyEvents';
import CalendarEvents from './pages/CalendarEvents';
import MyEvents from './pages/MyEvents';
import User from './pages/User';
import ManageEvents from './pages/ManageEvents';
import BottomNav from './components/layout/BottomNav';
import InitiationScreen from './pages/InitiationScreen';
import ClearCache from './pages/ClearCache';
import CreateEvent from './pages/CreateEventScreen';
import EditEvent from './pages/EditEventScreen';
import ViewEvent from './pages/ViewEvent';
import InitialSetup from './pages/InitialSetup';
import LocalStorageSync from './components/maintenance/LocalStorageSync'; // Import LocalStorageSync component
import './App.css';
import StandaloneNavigation from './components/layout/StandaloneNavigation';

const App = () => {
  const [showInitiationScreen, setShowInitiationScreen] = useState(true);
  const [userId, setUserId] = useState(null); // Track user ID in state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid); // Set user ID in state
        localStorage.setItem('userId', user.uid); // Save user ID
      } else {
        localStorage.removeItem('userId'); // Clear user ID on logout
      }
    });
    return unsubscribe; // Clean up listener on unmount
  }, []);

  const handleInitiationComplete = () => {
    setShowInitiationScreen(false); // Hide the initiation screen when it's complete
  };

  return (
    <Router>
      <div className="App">
        <StandaloneNavigation />

        <InitiationScreenWrapper showInitiationScreen={showInitiationScreen} onComplete={handleInitiationComplete} />

        {/* Call LocalStorageSync when userId is available */}
        {userId && <LocalStorageSync userId={userId} />}

        <Routes>
          <Route path="/" element={<WeeklyEvents />} />
          <Route path="/events" element={<WeeklyEvents />} />
          <Route path="/calendar" element={<CalendarEvents />} />
          <Route path="/myevents" element={<MyEvents />} />
          <Route path="/profile" element={<User />} />
          <Route path="/manageevents" element={<ManageEvents />} />
          <Route path="/clear-cache" element={<ClearCache />} />
          <Route path="/create-event" element={<CreateEvent />} />
          <Route path="/edit-event/:eventId" element={<EditEvent />} />
          <Route path="/view-event/:eventId" element={<ViewEvent />} />
          <Route path="/setup" element={<InitialSetup />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        <BottomNavWrapper />

        <CacheCheck />
      </div>
    </Router>
  );
};

// Redirect to setup if no cached locations or dance types
const CacheCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      const cachedCounties = localStorage.getItem('selectedCounties');
      const cachedDances = localStorage.getItem('selectedDances');
      const timestamp = localStorage.getItem("dataTimestamp");

      if (cachedCounties && cachedDances) {
        const selectedCounties = JSON.parse(cachedCounties);
        const selectedDances = JSON.parse(cachedDances);
        if (selectedCounties.length === 0 || selectedDances.length === 0) {
          console.log('Redirecting to setup page');
          navigate('/setup');
        }
        else {
          if (!isTimestampValid || !timestamp) {
           eventFetch();  // Fetch events if cached data
           console.log("Fetching events (timeout and page change)");
          }
          else {
            console.log("Page change without fetching events");
          }
        }
      } else {
        console.log('No cached data found.');
        navigate('/setup');
      }
    }, 1);  // Delay by 1ms for reliable cache access
  }, [navigate]);

  return null;  // No UI is rendered
};

// Function to check if data timestamp is recent (within 10 minutes)
const isTimestampValid = () => {
  const timestamp = localStorage.getItem("dataTimestamp");
  if (!timestamp) return false;
  const timestampDate = new Date(timestamp);
  // return false
  return (new Date() - timestampDate) < 10 * 60 * 1000; // 10 minutes in milliseconds
};


// Create a wrapper component to handle the InitiationScreen
const InitiationScreenWrapper = ({ showInitiationScreen, onComplete }) => {
  const location = useLocation(); // Get the current location

  return (
    <>
      {location.pathname !== '/install' && location.pathname !== '/clear-cache' && showInitiationScreen && (
        <InitiationScreen onComplete={onComplete} />
      )}
    </>
  );
};

// Create a wrapper component to handle the BottomNav
const BottomNavWrapper = () => {
  const location = useLocation(); // Get the current location

  return (
    <>
      {location.pathname !== '/setup' && location.pathname !== '/install' && !location.pathname.startsWith('/view-event') && location.pathname !== '/clear-cache' && <BottomNav />} {/* Conditionally render BottomNav */}
    </>
  );
};

export default App;
