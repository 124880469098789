import React, { useState, useEffect } from 'react';
import Input from '../components/Input';
import SlideUpModal from '../components/SlideUpModal';
import Button from '../components/Button';
import searchIcon from '../../assets/icons/icon-search-brand.png';

const Location = ({ location, setLocation, setShortLocation }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressInput, setAddressInput] = useState('');
  const [showFields, setShowFields] = useState(!!location.venue); // Show fields if venue is already set

  // Populate addressInput if location data exists when component mounts or updates
  useEffect(() => {
    if (location.street || location.city || location.county || location.postcode || location.country) {
      const formattedAddress = [
        location.street || '',
        location.city || '',
        location.county || '',
        location.postcode || '',
        location.state || '',
        location.country || '',
      ].filter(Boolean).join('\n');
      setAddressInput(formattedAddress);
    }
  }, [location]);

  // Fetch from Photon API on button click
  const handleSearchClick = async () => {
    if (query.length > 4) {
      setIsLoading(true);
      setShowFields(false);
      setResults([]);
      // setLocation({  // clear location if already set
      //   venue: '',
      //   street: '',
      //   city: '',
      //   county: '',
      //   postcode: '',
      //   state: '',
      //   country: '',
      //   description: '', 
      // });
      try {
        const response = await fetch(`https://photon.komoot.io/api/?q=${query}+United+Kingdom&limit=5`);
        const data = await response.json();

        // Filter results to only include those with a postcode
        const filteredResults = data.features.filter(
          (feature) => feature.properties.postcode
        );

        setResults(filteredResults);

        if (filteredResults.length > 1) {
          setIsModalOpen(true);
        } else if (filteredResults.length === 1) {
          handleSelectLocation(filteredResults[0]);
        } else {
          setAddressInput("No results for the entered address. Please try again. Hint: Don't be too specific, e.g. try 'Brighton Revolution' or 'London ABC Dance Studio'.");
          setShowFields(false);
        }
      } catch (error) {
        console.error('Error fetching location data:', error);
        setAddressInput('Error fetching address');
        setShowFields(false);
      }
      setIsLoading(false);
    } else {
      setAddressInput('Query too short');
      setShowFields(false);
      setResults([]);
    }
  };

  // Populate shortLocation with the town from the postcode
  const getCityFromPostcode = async (postcode) => {
    try {
      const response = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);
      const data = await response.json();
      if (data.status === 200 && data.result) {
        return data.result.admin_district || data.result.town || data.result.city || location.county || null; // Prioritize admin_district, then town, then city
      } else {
        console.error("Postcode API error:", data);
        return null;
      }
    } catch (error) {
      console.error("Error fetching postcode data:", error);
      return null;
    }
  };

  // Handle location selection
  const handleSelectLocation = async (address) => {
    const {
      name = '',
      street = '',
      city = '',
      county = '',
      postcode = '',
      state = '',
      country = '',
    } = address.properties;

    const formattedAddress = [
      street || '',
      city || '',
      county || '',
      postcode || '',
      state || '',
      country || '',
    ].filter(Boolean).join('\n');

    setAddressInput(formattedAddress);
    setLocation({
      venue: name || street || '', // Prioritize name, then fallback to street or empty
      street,
      city,
      county,
      postcode,
      state,
      country,
      description: location.description || '', // Maintain location description
    });
    setShowFields(true);
    setIsModalOpen(false);

    const postcodeCity = await getCityFromPostcode(postcode); // Get city from postcode API
    setShortLocation(postcodeCity); 
  };

  return (
    <div className="layout">
      {/* Search Address section */}
      <h2>Search address</h2>
      <Input
        type="location"
        placeholder="Search for address"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />

      <Button
        variant="secondary"
        label={isLoading ? "Searching address..." : "Search address"}
        icon={searchIcon}
        onClick={handleSearchClick}
        disabled={isLoading}
      />

      {addressInput === 'No address results' && <p>No address results found. Please try again.</p>}
      {addressInput === 'Error fetching address' && <p>Error fetching the address. Please try again.</p>}
      {addressInput === 'Query too short' && <p>The searched address is too short.</p>}


      {showFields && (
        <>
          <h2>Venue</h2>
          <Input
            type="location"
            placeholder="Name of venue"
            value={location.venue}
            onChange={(e) => setLocation({ ...location, venue: e.target.value })}
          />

          <h2>Address</h2>
          <Input
            type="address"
            placeholder="Address"
            value={addressInput}
            multiline={true}
            disabled={true}
          />

          <h2>Find us (optional)</h2>
          <Input
            type="description"
            placeholder="Any extra help or instructions&#10;e.g. past reception, on the 2nd floor"
            value={location.description}
            multiline={true}
            showIcon={false}
            onChange={(e) => setLocation({ ...location, description: e.target.value })}
          />

          {/* Attribution to OpenStreetMap */}
          <small>
            Location data from <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a>.
          </small>
        </>
      )}

      <SlideUpModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Select an address"
      >
        {results.map((result) => (
          <li key={result.properties.osm_id} onClick={() => handleSelectLocation(result)}>
            {[
              result.properties.name,
              result.properties.street,
              result.properties.town,
              result.properties.city,
              result.properties.county,
              result.properties.postcode,
              result.properties.country,
            ]
              .filter(Boolean)
              .join(', ')}
          </li>
        ))}
      </SlideUpModal>
    </div>
  );
};

export default Location;
