// src/components/components/SelectionCard.js
import React, { useState, useEffect } from 'react';
import './SelectionCard.css'; // Import your CSS styles

const SelectionCard = ({ items, layout = 'single', selectedItems: initialSelectedItems = [], onSelectionChange }) => {
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

  // Update state when the initial selection changes (e.g., from cached data)
  useEffect(() => {
    setSelectedItems(initialSelectedItems);
  }, [initialSelectedItems]);

  const toggleSelect = (item) => {
    const isSelected = selectedItems.includes(item);
    const updatedSelection = isSelected
      ? selectedItems.filter((selectedItem) => selectedItem !== item)
      : [...selectedItems, item];

    setSelectedItems(updatedSelection);
    onSelectionChange(updatedSelection);
  };

  return (
    <div className={`selection-container ${layout}`}>
      {items.map((item) => (
        <div
          key={item}
          className={`selection-card ${selectedItems.includes(item) ? 'selected' : ''}`}
          onClick={() => toggleSelect(item)}
        >
          <p className="selection-card-text">{item}</p>
        </div>
      ))}
    </div>
  );
};

export default SelectionCard;
