// src/components/components/Checkbox.js
import React, { useState, useEffect } from 'react';
import './Checkbox.css';
import tickIcon from '../../assets/icons/icon-tick-white.png';

const Checkbox = ({ labels = [], selectedLabels = [], onSelectionChange }) => {
  // Track the state of checked checkboxes
  const [checkedState, setCheckedState] = useState(
    labels.map((label) => selectedLabels.includes(label)) // Initialize based on selected labels
  );

  // Update checkedState when selectedLabels prop changes (e.g., when modal is reopened)
  useEffect(() => {
    setCheckedState(labels.map((label) => selectedLabels.includes(label)));
  }, [selectedLabels, labels]);

  const handleCheckboxChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
    onSelectionChange(
      updatedCheckedState
        .map((checked, index) => (checked ? labels[index] : null))
        .filter((label) => label !== null) // Return only selected labels
    );
  };

  return (
    <div className="checkbox-list">
      {labels.map((label, index) => (
        <label key={index} className="checkbox-container">
          <input
            type="checkbox"
            checked={checkedState[index]}
            onChange={() => handleCheckboxChange(index)}
          />
          <span className="custom-checkbox">
            {checkedState[index] && <img src={tickIcon} alt="Tick" />}
          </span>
          <span className="checkbox-label">{label}</span>
        </label>
      ))}
    </div>
  );
};

export default Checkbox;
