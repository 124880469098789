// src/components/components/AddItem.js
import React from 'react';
import './AddItem.css';
import plusIcon from '../../assets/icons/icon-plus-brand.png';

const AddItem = ({ text, onClick }) => {
  return (
    <div className="add-item" onClick={onClick}>
      <div className="add-item-icon">
        <img src={plusIcon} alt="Add" />
      </div>
      <span className="add-item-text">{text}</span>
    </div>
  );
};

export default AddItem;
