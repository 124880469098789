// src/pages/EditEventScreen.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDocs, where, query, addDoc, updateDoc } from "firebase/firestore";
import { validateEvent } from '../data/eventValidation';
import PageHeader from '../components/layout/PageHeader';
import TopNav from '../components/layout/TopNav';
import Event from '../components/new-event/Event';
import About from '../components/new-event/About';
import Agenda from '../components/new-event/Agenda';
import Pricing from '../components/new-event/Pricing';
import Location from '../components/new-event/Location';
import Branding from '../components/new-event/Branding';
import PageFooter from '../components/layout/PageFooter';
import Button from '../components/components/Button';
import SlideUpModal from '../components/components/SlideUpModal';
import eventUpdate from '../components/edit-event/eventUpdate';
import eventImageSave from '../components/edit-event/eventImageSave';

import './CreateEventScreen.css';
import '../components/new-event/new-event.css';

import cancelIcon from '../assets/icons/icon-cancel-brand.png';
import tickIcon from '../assets/icons/icon-tick-white.png';
import firebaseConfig from '../firebaseConfig';
import eventStorage from '../components/events/eventStorage';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const EditEventScreen = () => {
  const navigate = useNavigate();
  const { eventId } = useParams(); // Extract eventId from url
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const occurrenceDate = urlParams.get('date'); // Extract occurrenceDate (if provided) from url
  const eventSeries = !occurrenceDate; // Boolean, true if editing the entire event series, false if editing a single occurrence

  // Define the tabs for the top navigation
  const tabs = [
    { id: 'event', label: 'Event' },
    { id: 'about', label: 'About' },
    { id: 'agenda', label: 'Agenda' },
    { id: 'pricing', label: 'Pricing' },
    { id: 'location', label: 'Location' },
    { id: 'branding', label: 'Branding' },
  ];

  // Define form validation
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  // Define form state for data across the tabs (pre-populated with event data)
  const [eventName, setEventName] = useState('');
  const [shortLocation, setShortLocation] = useState('');
  const [description, setDescription] = useState('');
  const [team, setTeam] = useState('');
  const [danceTypes, setDanceTypes] = useState([]);
  const [isRecurring, setIsRecurring] = useState(true);
  const [selectedDay, setSelectedDay] = useState(0);
  const [eventDate, setEventDate] = useState('');
  const [eventEndDate, setEventEndDate] = useState('');
  const [eventDays, setEventDays] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  // Agenda (timeslots) state
  const [timeslots, setTimeslots] = useState([]);

  // Pricing state (pricing items)
  const [priceSlots, setPriceSlots] = useState([]);

  // Location state
  const [locationData, setLocationData] = useState({
    venue: '',
    street: '',
    city: '',
    county: '',
    postcode: '',
    state: '',
    country: '',
    description: '',
  });

  // Branding image and image file state
  const [imageUrl, setImageUrl] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState(''); 
  const [imageFile, setImageFile] = useState(null); 
  const [thumbnailFile, setThumbnailFile] = useState(null); 

  // Manage active tab state
  const [activeTab, setActiveTab] = useState('event');

  // Reset scroll position to top when active tab changes
  useEffect(() => {
    const contentContainer = document.querySelector('.create-event-content');
    if (contentContainer) {
      contentContainer.scrollTo(0, 0); // Reset scroll to top
    }
  }, [activeTab]); // Trigger this effect when activeTab changes

// Function to add county if it doesn't exist
const addCountyIfNotExists = async (county) => {
    const countyQuery = query(collection(db, 'counties'), where('name', '==', county));
    const querySnapshot = await getDocs(countyQuery);

    if (querySnapshot.empty) {
      // If county does not exist, add it
      await addDoc(collection(db, 'counties'), { name: county });
      console.log(`Added new county: ${county}`);
      // Select the new county for the user's county selection
      // INSERT CODE HERE
    } else {
      console.log(`County ${county} already exists.`);
    }
  };

  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const filters = { eventId };
        const event = await eventStorage({
          filters,
          onlyDances: false,
          onlyCounties: false,
          occurrenceDate, // If provided in the url
          eventSeries, // Boolean, true if wanting the entire event series, false if wanting a one off occurrence/exception
        });
        setEventData(event[0]);  // eventData will now contain the event
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };

    fetchEvent();
  }, [eventId, occurrenceDate]);

  useEffect(() => {
    if (eventData) {
      setEventName(eventData.eventName || '');
      setShortLocation(eventData.shortLocation || '');
      setDescription(eventData.description || '');
      setTeam(eventData.team ? eventData.team : '');
      setDanceTypes(eventData.danceTypes || []);
      setIsRecurring(eventData.isRecurring ?? true); // Use nullish coalescing
      setSelectedDay(eventData.recurrenceDay || 0);
      setEventDate(eventData.eventDate || '');
      setEventEndDate(eventData.eventEndDate || '');
      setEventDays(eventData.eventDays ? eventData.eventDays : [])
      setStartTime(eventData.startTime || '');
      setEndTime(eventData.endTime || '');
      setTimeslots(eventData.agenda || []);
      setPriceSlots(eventData.pricing || []);
      setLocationData(eventData.location || {
        venue: '',
        street: '',
        city: '',
        county: '',
        postcode: '',
        state: '',
        country: '',
        description: '',
      });
      setImageUrl(eventData.imageUrl || '');
      setThumbnailUrl(eventData.thumbnailUrl || '');
    }
  }, [eventData]);

  const handleEditEvent = async () => {
    setDisableSubmit(true);

    const updatedEventDataFull = {
        eventName,
        shortLocation,
        description,
        team,
        danceTypes,
        isRecurring,
        recurrenceDay: isRecurring ? selectedDay : null,
        eventDate: !isRecurring ? eventDate : null,
        eventEndDate: !isRecurring ? eventEndDate : null,
        eventDays: !isRecurring ? eventDays : null,
        startTime,
        endTime,
        agenda: timeslots.map(slot => ({ ...slot })), // Create new objects to avoid mutation
        pricing: priceSlots.map(slot => ({ ...slot })),
        location: { ...locationData }, // Create new location object
        imageUrl,
      };

    // Determine which fields have changed
  const updatedEventData = {};
  if (eventName !== eventData.eventName) updatedEventData.eventName = eventName;
  if (description !== eventData.description) updatedEventData.description = description;
  if (team?.trim() !== eventData.team?.trim()) updatedEventData.team = team;
  if (JSON.stringify(danceTypes) !== JSON.stringify(eventData.danceTypes)) updatedEventData.danceTypes = danceTypes;
  if (isRecurring !== eventData.isRecurring) updatedEventData.isRecurring = isRecurring;
  if (isRecurring && selectedDay !== eventData.recurrenceDay) updatedEventData.recurrenceDay = selectedDay;
  if (!isRecurring && eventDate !== eventData.eventDate) updatedEventData.eventDate = eventDate;
  if (!isRecurring && eventEndDate !== eventData.eventEndDate) updatedEventData.eventEndDate = eventEndDate;
  if (!isRecurring && eventDays !== eventData.eventDays) updatedEventData.eventDays = eventDays;
  if (startTime !== eventData.startTime) updatedEventData.startTime = startTime;
  if (endTime !== eventData.endTime) updatedEventData.endTime = endTime;
  if (JSON.stringify(timeslots) !== JSON.stringify(eventData.agenda)) updatedEventData.agenda = timeslots.map(slot => ({
      id: slot.id,
      slotName: slot.slotName || '',
      startTime: slot.startTime || null,
      endTime: slot.endTime || null,
      ...(slot.levels && slot.levels.length > 0 && { levels: slot.levels }),
      ...(slot.date && {date: slot.date})
  }));
  if (JSON.stringify(priceSlots) !== JSON.stringify(eventData.pricing)) updatedEventData.pricing = priceSlots.map(slot => ({ ...slot }));
  if (JSON.stringify(locationData) !== JSON.stringify(eventData.location)) {
    updatedEventData.location = { ...locationData };
    updatedEventData.shortLocation = shortLocation;
  }

    const errors = validateEvent(updatedEventDataFull);
    if (errors.length > 0) {
      setValidationErrors(errors);
      setIsModalOpen(true);
      setDisableSubmit(false);
      return;
    }

    try {
        // Upload images if they've changed
        if (imageFile) {
            try {
                const storageUrls = await eventImageSave(eventId, occurrenceDate, { eventImage: imageFile, thumbnailImage: thumbnailFile });
                updatedEventData.imageUrl = storageUrls.imageUrl;
                updatedEventData.thumbnailUrl = storageUrls.thumbnailUrl;
              } catch (storageError) {
                  console.error("Image upload failed:", storageError);
                  // Handle the error, e.g., display a message to the user
                  setDisableSubmit(false); // Re-enable submit
                  return; // Stop the rest of the save operation
              }
            }

      if (occurrenceDate) {
        // Use eventUpdate to save the updated fields
        await eventUpdate(eventId, occurrenceDate, eventData.exceptionIndex, updatedEventData);
      } else {
        // Or update the entire event if no occurrence date
        await updateDoc(doc(db, 'events', eventId), updatedEventData);
      }


      // Add county if not already in the list
      if (updatedEventData.shortLocation) {
        console.log("Event short location: ", updatedEventData.shortLocation)
        console.log("Updated shortlocation: ", shortLocation)
        await addCountyIfNotExists(shortLocation);
      }

      // Add county to their selected locations if not already
      const userCounties = JSON.parse(localStorage.getItem('selectedCounties')) || [];
      if (!userCounties.includes(shortLocation))  userCounties.push(shortLocation);
      localStorage.setItem('selectedCounties', JSON.stringify(userCounties));
      

      navigate(`/view-event/${eventId}${occurrenceDate ? `?date=${occurrenceDate}` : ''}`, { state: { from: '/edit-event' } });
    } catch (error) {
      console.error('Error updating event:', error);
    } finally {
      setDisableSubmit(false);
    }
  };


  const footerButtons = [
    {
      component: (
        <Button
          variant="secondary"
          label="Cancel"
          icon={cancelIcon}
          onClick={() => navigate(-1)}
        />
      )
    },
    {
      component: (
        <Button
          variant="primary"
          label="Save"
          icon={tickIcon}
          onClick={handleEditEvent}
          disabled={disableSubmit}
        />
      )
    }
  ];

  const renderTabContent = () => {
        switch (activeTab) {
      case 'event':
        return (
          <Event
            danceTypes={danceTypes}
            setDanceTypes={setDanceTypes}
            isRecurring={isRecurring}
            setIsRecurring={setIsRecurring}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            eventDate={eventDate}
            setEventDate={setEventDate}
            eventEndDate={eventEndDate}
            setEventEndDate={setEventEndDate}
            eventDays={eventDays}
            setEventDays={setEventDays}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            restrictedEdit={true} //{!!occurrenceDate} // Restricted edit if editing a single occurrence
          />
        );
      case 'about':
        return (
          <About
            eventName={eventName}
            setEventName={setEventName}
            description={description}
            setDescription={setDescription}
            team={team}
            setTeam={setTeam}
          />
        );
      case 'agenda':
        return (
          <Agenda
            timeslots={timeslots}
            setTimeslots={setTimeslots}
            eventDays={eventDays}
            eventDate={eventDate}
          />
        );
      case 'pricing':
        return (
          <Pricing
            pricingItems={priceSlots}
            setPricingItems={setPriceSlots}
          />
        );
      case 'location':
        return (
          <Location
            location={locationData}
            setLocation={setLocationData}
            setShortLocation={setShortLocation}
          />
        );
      case 'branding':
        return (
          <Branding
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            setImageFile={setImageFile}
            setThumbnailUrl={setThumbnailUrl}
            setThumbnailFile={setThumbnailFile}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="create-event-page">
      <PageHeader title="Edit Event" /> {/* Updated title */}
      <TopNav tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />
      <div className="create-event-content">
        {renderTabContent()}
      </div>
      <PageFooter buttons={footerButtons} />
      <SlideUpModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Validation Errors"
      >
        <ul>
          {validationErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </SlideUpModal>
    </div>
  );
};

export default EditEventScreen;