// src/components/components/SlideUpModal.js
import React, { useEffect } from 'react';
import './SlideUpModal.css';
import Button from './Button'; // Import the new Button component

const SlideUpModal = ({ 
  isOpen, 
  onClose, 
  title, 
  children, 
  primaryActionLabel = null, 
  primaryActionIcon = null, 
  onPrimaryAction = null 
}) => {
  // Handle closing the modal when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains('modal-overlay')) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  

  // Prevent clicks inside the modal from propagating to underlying elements
  const handleModalClick = (event) => {
    event.stopPropagation(); // Prevents the click from reaching the overlay
  };

  // // Handle swipe down to close (touch devices)
  // let touchStartY = 0;
  // const handleTouchStart = (e) => {
  //   touchStartY = e.touches[0].clientY;
  // };

  // const handleTouchEnd = (e) => {
  //   const touchEndY = e.changedTouches[0].clientY;
  //   if (touchEndY - touchStartY > 100) {
  //     onClose();
  //   }
  // };


  if (!isOpen) return null; // Don't render if the modal is not open

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="slide-up-modal"
        onClick={handleModalClick}  // Prevent clicks from bubbling up
        // onTouchStart={handleTouchStart}
        // onTouchEnd={handleTouchEnd}
      >
        {/* Modal header */}
        <div className="modal-header">
          <h2>{title}</h2>
          <button className="close-btn" onClick={onClose}>
            &times;
          </button>
        </div>

        {/* Modal content */}
        <div className="modal-content">{children}</div>

        {/* Primary action button (optional footer) */}
        {onPrimaryAction && primaryActionLabel && (
          <div className="modal-footer">
            <Button
              variant="primary"
              label={primaryActionLabel}
              icon={primaryActionIcon}
              onClick={onPrimaryAction}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SlideUpModal;