// src/components/new-event/Pricing.js
import React, { useEffect } from 'react';
import Button from '../components/Button';
import Priceslot from './children/Priceslot';
import iconPlusBrand from '../../assets/icons/icon-plus-brand.png';
import { v4 as uuidv4 } from 'uuid';

const Pricing = ({ pricingItems, setPricingItems }) => {
  // Safeguard against undefined pricingItems
  if (!pricingItems || !Array.isArray(pricingItems)) {
    pricingItems = [];
  }

  const handleAddItem = () => {
    setPricingItems([...pricingItems, { id: uuidv4(), itemName: '', selectedDiscounts: ['Standard'], prices: {} }]);
  };

  const handleDeleteItem = (id) => {
    setPricingItems(pricingItems.filter((item) => item.id !== id));
  };

  const handleCopyItem = (id) => {
    const itemToCopy = pricingItems.find((item) => item.id === id);
    if (itemToCopy) {
      setPricingItems([...pricingItems, { ...itemToCopy, id: uuidv4() }]);
    }
  };

  const orderTimeslot = (id, movement) => {
    // Get current index of the item in the array
    const currentItemIndex = pricingItems.findIndex((item) => item.id === id);
    // Calculate its new index
    const newIndex = currentItemIndex + movement;
    if (newIndex < 0 || newIndex >= pricingItems.length) return;

    const updatedPricingItems = [...pricingItems];
    const [movedItem] = updatedPricingItems.splice(currentItemIndex, 1);
    updatedPricingItems.splice(newIndex, 0, movedItem);

    setPricingItems(updatedPricingItems);
  };

  const handleUpdateItem = (updatedItem) => {
    const updatedPricingItems = pricingItems.map((item) =>
      item.id === updatedItem.id ? updatedItem : item
    );
    setPricingItems(updatedPricingItems);
  };

  return (
    <div className="layout">
      <h2>Pricing</h2>

      <div className="pricing-section layout">
        {pricingItems.map((item) => (
          <Priceslot
            key={item.id}
            itemNumber={item.id}
            initialData={item} // Pass the existing item data as initialData
            onDelete={() => handleDeleteItem(item.id)}
            onCopy={() => handleCopyItem(item.id)}
            onUpdate={handleUpdateItem}
            onMoveUp={() => orderTimeslot(item.id, -1)}
            onMoveDown={() => orderTimeslot(item.id, 1)}
          />
        ))}

        <Button
          label="Add item"
          icon={iconPlusBrand}
          variant="secondary"
          onClick={handleAddItem}
        />
      </div>
    </div>
  );
};

export default Pricing;