// src/components/events/eventFetch.js
// This component will fetch all events that the user might want and saves them to indexed db
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { openDB } from "idb";
import firebaseConfig from "../../firebaseConfig";

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Initialize and retrieve the IndexedDB database
const getDB = async () => {
  return await openDB("EventDB", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("events")) {
        const store = db.createObjectStore("events", { keyPath: "eventId" });
        store.createIndex("recurrenceDay", "recurrenceDay", { unique: false });
        store.createIndex("eventDate", "eventDate", { unique: false });
        store.createIndex("eventDays", "eventDays", { unique: false });
        store.createIndex("shortLocation", "shortLocation", { unique: false });
      }
    },
  });
};

// Fetch function for events
const fetchEvents = async () => {
  try {
    // Retrieve selected dances and counties from localStorage
    const selectedDances = JSON.parse(localStorage.getItem("selectedDances") || "[]");
    const selectedCounties = JSON.parse(localStorage.getItem("selectedCounties") || "[]");
    const userId = localStorage.getItem("userId") || "";

    // Create and execute Firestore queries
    const eventList = await createEventsQuery(selectedDances, selectedCounties, userId);
    console.log(`Number of events retrieved after combining queries: ${eventList.length}`);

    // Save events to IndexedDB
    const db = await getDB();
    const tx = db.transaction("events", "readwrite");

    // Clear old events before adding the new ones
    await tx.store.clear();

    // Insert the new events into IndexedDB
    await Promise.all(eventList.map(event => tx.store.put(event)));
    await tx.done;

    console.log("Events successfully stored in IndexedDB.");

    // Update the timestamp in localStorage to record when events were last fetched
    const dataTimestamp = new Date().toISOString();
    localStorage.setItem("dataTimestamp", dataTimestamp);
    console.log("Data timestamp updated in localStorage:", dataTimestamp);

    localStorage.setItem("selectedDancesOutdated", false); // Reset filterchange flags
    localStorage.setItem("selectedCountiesOutdated", false);

  } catch (err) {
    console.error("Error during fetch:", err);
  }
};

// Export fetchEvents directly
export const eventFetch = fetchEvents;

// Function to create and combine Firestore queries
const createEventsQuery = async (danceTypes, counties, userId) => {
  const countyDanceQuery = query(
    collection(db, "events"),
    where("shortLocation", "in", counties),
    where("danceTypes", "array-contains-any", danceTypes)
  );

  const creatorQuery = query(
    collection(db, "events"),
    where("creator", "==", userId)
  );

  const [countyDanceSnapshot, creatorSnapshot] = await Promise.all([
    getDocs(countyDanceQuery),
    getDocs(creatorQuery)
  ]);

  const countyDanceEvents = countyDanceSnapshot.docs.map(doc => mapEventData(doc));
  const creatorEvents = creatorSnapshot.docs.map(doc => mapEventData(doc));

  console.log("Number of events matching county and dance type:", countyDanceEvents.length);
  console.log("Number of events created by user:", creatorEvents.length);

  const uniqueEvents = [
    ...countyDanceEvents,
    ...creatorEvents.filter(event => !countyDanceEvents.some(e => e.eventId === event.eventId))
  ];

  return uniqueEvents;
};

// Function to map Firestore document to event data model
const mapEventData = (doc) => {
  const data = doc.data();
  return {
    eventId: doc.id,
    creator: data.creator,
    approved: data.approved,
    announcementText: data.announcementText,
    isCancelled: data.isCancelled,
    eventName: data.eventName,
    shortLocation: data.shortLocation,
    imageUrl: data.imageUrl,
    thumbnailUrl: data.thumbnailUrl,
    timestamp: data.timestamp,
    description: data.description,
    team: data.team,
    danceTypes: data.danceTypes,
    isRecurring: data.isRecurring,
    recurrenceDay: data.recurrenceDay,
    eventDate: data.eventDate,
    eventEndDate: data.eventEndDate,
    eventDays: data.eventDays,
    startTime: data.startTime,
    endTime: data.endTime,
    location: {
      venue: data.location.venue,
      street: data.location.street,
      city: data.location.city,
      county: data.location.county,
      postcode: data.location.postcode,
      state: data.location.state,
      country: data.location.country,
      description: data.location.description,
    },
    agenda: data.agenda || [],
    pricing: data.pricing || [],
    exceptions: data.exceptions || []
  };
};

export default eventFetch;
