// src/utils/eventValidation.js

export const validateEvent = (eventData, imageUrl) => {
    const errors = [];
  
    // Initial validation
    if (!eventData.eventName && (eventData.danceTypes.length === 0 || !eventData.description || !eventData.startTime)) {
      errors.push('Please complete the appropriate fields to create the event.');
      return errors;  // Don't bother checking all validation if fails this test
    }
    
    // Check required fields
    if (!eventData.eventName || eventData.eventName.length < 3) {
      errors.push('Event Name must be at least 3 characters.');
    }
  
    if (!eventData.description || eventData.description.length < 15) {
      errors.push('Event description must be at least 15 characters.');
    }
  
    if (!eventData.danceTypes || eventData.danceTypes.length === 0) {
      errors.push('At least one Dance Type must be selected.');
    }
  
    if (!eventData.startTime) {
      errors.push('Start Time is required.');
    }

    if (!eventData.location.venue) {
      errors.push('Location is required.');
    }
  
    const allowedCountries = ['United Kingdom', 'England', 'Great Britain'];
    if (eventData.location.venue && !allowedCountries.includes(eventData.location.country)) {
      errors.push('Find Your Rhythm is currently only available in England. Contact find.your.rhythm@hotmail.com if you would like us to make this available in more places.');
    }
  
    if (!eventData.isRecurring && !eventData.eventDate) {
      errors.push('Event Date is required for one-off events.');
    }

    if (eventData.agenda && eventData.agenda.length > 0) { 
      eventData.agenda.forEach((timeslot, index) => {
        if (!eventData.agenda[index].slotName || !eventData.agenda[index].startTime || !eventData.agenda[index].endTime) {
          errors.push('Each timeslot needs a name, start time and end time.');
        }
      })
    }



// Validate prices 
    const validatePrice = (price) => {
      if (!price) {
        return 'Price is missing.';
      }
      // if (typeof price !== 'number') {
      //   return 'Price must be a number.';
      // }
      if (Math.round(price * 100) !== price * 100) {
        return 'Prices must have at most 2 decimal places.';
      }
      return null; // No error
    };
    
    if (eventData.pricing && eventData.pricing.length > 0) {
      eventData.pricing.forEach((priceslot, priceslotIndex) => {
        if (!priceslot.itemName || !priceslot.prices || typeof priceslot.prices !== 'object' || Object.keys(priceslot.prices).length === 0) {
          errors.push(`Each pricing item needs a name and at least one price.`);
          return; // Skip further checks for this priceslot if no prices object
        }
    
        Object.entries(priceslot.prices).forEach(([label, price], priceIndex) => {
          const priceError = validatePrice(price);
          if (priceError) {
            errors.push(`${priceError}`);
          }
        });
      });
    } else {
     //   errors.push("At least one pricing item must be provided.")
     // Revisit this rule to check it works
    }


    // Validate images
    // if (!imageUrl && !eventData.imageUrl) {
    //   errors.push('Please upload an image for your event');
    // }

  
    return errors;
  };
  