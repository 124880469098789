// src/pages/ClearCache.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ClearCache = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear browser caches (cacheStorage)
    if ('caches' in window) {
      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });
    }

    // Clear localStorage and sessionStorage
    localStorage.clear();
    sessionStorage.clear();

    // Unregister all service workers
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }

    // Show a message and redirect after clearing cache
    alert('Cache and saved data cleared! Redirecting to homepage...');
    navigate('/'); // Redirect to home page after clearing
  }, [navigate]);

  return (
    <div>
      <h1>Clearing Cache...</h1>
      <p>Please wait while we clear your cache and stored data.</p>
    </div>
  );
};

export default ClearCache;
