// src/components/setup/OurMission.js
import React from 'react';

const OurMission = () => {

      return (
        <div className='page-install styledText'>
          <p>Keeping you connected to your favourite local dance events</p>
          <p>A one stop place for updates, venue changes, and more</p>
          <p>and helping you to find your next dance when you choose to explore a new city</p>
        </div>
      );

}
  
  export default OurMission;
  