// src/components/components/Input.js
import React from 'react';
import './Input.css';
import iconLabel from '../../assets/icons/inputicon-label.png';
import iconTime from '../../assets/icons/inputicon-time.png';
import iconDate from '../../assets/icons/inputicon-date.png';
import iconPrice from '../../assets/icons/inputicon-price.png';
import iconLocation from '../../assets/icons/inputicon-location.png';
import iconEmmail from '../../assets/icons/inputicon-email.png';
import iconPassword from '../../assets/icons/inputicon-password.png';

const Input = ({
  type = 'text',         // Type of input field ('text', 'time', 'date', 'price', etc.)
  placeholder = '',      // Placeholder text
  value = '',            // Default value of input
  required = false,      // Whether input is required or not
  showIcon = true,       // Show or hide the icon
  multiline = false,     // Whether it's a multi-line input (for description)
  onChange,              // Function to handle input changes
  disabled = false,      // Disabled state (false by default)
}) => {
  // Define the icon based on the input type
  let iconSrc = '';
  switch (type) {
    case 'text':
      iconSrc = iconLabel;
      break;
    case 'description':
      iconSrc = iconLabel;
      break;
    case 'time':
      iconSrc = iconTime;
      break;
    case 'date':
      iconSrc = iconDate;
      break;
    case 'price':
      iconSrc = iconPrice;
      break;
    case 'location':
      iconSrc = iconLocation;
      break;
    case 'email':
      iconSrc = iconEmmail;
      break;
    case 'password':
      iconSrc = iconPassword;
      break;
    default:
      iconSrc = '';
  }

  const handlePriceChange = (event) => {
    let inputValue = event.target.value;
    // Remove any non-numeric characters except for the decimal point
    inputValue = inputValue.replace(/[^0-9.]/g, '');
  
    // Ensure only one decimal point
    if ((inputValue.split('.').length - 1) > 1) {
      inputValue = inputValue.substring(0, inputValue.lastIndexOf('.'));
    }
  
    // Limit to two decimal places
    if (inputValue.includes('.')) {
      const parts = inputValue.split('.');
      if (parts[1].length > 2) {
        inputValue = parts[0] + '.' + parts[1].substring(0, 2);
      }
    }
  
    // Create a synthetic event to update the parent component
    const syntheticEvent = {
      ...event,
      target: {
        ...event.target,
        value: inputValue, // Replace the value with the cleaned input
      },
    };
  
    onChange(syntheticEvent); // Call the parent onChange with the formatted value
  };
  

  return (
    <div className={`input-wrapper ${disabled ? 'disabled' : ''}`}>
      {showIcon && iconSrc && <img src={iconSrc} alt="icon" className="input-icon" />}
      {multiline ? (
        <textarea
          className="input-field"
          placeholder={placeholder}
          value={value}
          required={required}
          onChange={onChange}
          disabled={disabled}
        />
      ) : (
        <input
          className="input-field"
          type={type === 'price' ? 'text' : type} // Use 'text' type for price input
          placeholder={placeholder}
          value={value}
          required={required}
          onChange={type === 'price' ? handlePriceChange : onChange} // Use custom handler for price
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default Input;