// src/pages/User.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import LocalStorageSync from '../components/maintenance/LocalStorageSync'; // Import LocalStorageSync
import './User.css';
import Login from '../components/user/Login';
import AvatarHeader from '../components/user/AvatarHeader';
import PageHeader from '../components/layout/PageHeader';
import ListButton from '../components/components/ListButton';
import ServiceAgreement from '../components/user/ServiceAgreement';
import Feedback from '../components/user/Feedback';

const User = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [view, setView] = useState('menu');
  const [existingEvents, setExistingEvents] = useState(false); // If user has their own events or not

  // Check cache if user is already creator of events
  useEffect(() => {
    setTimeout(() => {
      if (user) {
        setExistingEvents(!!localStorage.getItem('eventCreator'));
    }
    }, 1);  // Delay by 1ms for reliable cache access
  }, [user]); 

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        localStorage.setItem('userId', user.uid);
        setView('menu');
      } else {
        localStorage.removeItem('userId');
      }
    });
    return unsubscribe;
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('permission');
      localStorage.removeItem('eventCreator');
      localStorage.removeItem('userId');
      setView('menu');
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleLoginSuccess = async () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        localStorage.setItem('userId', user.uid);
        setView('menu');
      }
    });
  };

  const getUserDisplayName = () => {
    if (user) {
      return user.displayName || user.email.split('@')[0];
    }
    return "user";
  };

  const renderContent = () => {
    switch (view) {
      case 'menu':
        return (
          <>
            <AvatarHeader name={getUserDisplayName()} />

            {!user ? (
              <ListButton text="Login / Sign-up" onClick={() => setView('login')} />
            ) : ( <>
              <ListButton text="Create an event" onClick={() => navigate(`/create-event`)} />
              <ListButton text="Manage events" onClick={() => navigate('/manageevents')} />
              </>
            )}

            <ListButton text="Service and Privacy Policy" onClick={() => setView('serviceAgreement')} />
            <ListButton text="Report bug / provide feedback" onClick={() => setView('feedback')} />
            {user ? (
              <ListButton text="Logout" onClick={handleLogout} />
            ) : (`` )}
          </>
        );
      case 'login':
        return (
          <>
            <PageHeader
              title="Login / Sign-up"
              showBackButton
              onBackClick={() => setView('menu')}
            />
            <Login onLoginSuccess={handleLoginSuccess} />
          </>
        );
      case 'serviceAgreement':
        return (
          <>
            <PageHeader
              title="Service and Privacy Policy"
              showBackButton
              onBackClick={() => setView('menu')}
            />
            <ServiceAgreement />
          </>
        );
      case 'feedback':
        return (
          <>
            <PageHeader
              title="Feedback"
              showBackButton
              onBackClick={() => setView('menu')}
            />
            <Feedback />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="user-screen">
      {user && <LocalStorageSync userId={user.uid} displayName={getUserDisplayName()}/>} {/* Sync data for logged-in users */}
      {renderContent()}
    </div>
  );
};

export default User;
