// src/components/view-event/Location.js
import React from 'react';
import mapsIcon from '../../assets/icons/googlemaps.png';

const Location = ({ location }) => {
  const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(
    `${location.venue}, ${location.street}, ${location.city}, ${location.postcode}, ${location.country}`
  )}`;

  return (
    <div className="layout">
      {location.description && (
        <div className="viewevent-container">
          <h3>Find us!</h3>
          <p>{location.description}</p>
        </div>
      )}

      <div className="viewevent-container address">
        <div className="address-info">
          <h3>Address</h3>
          <p>{location.venue}</p>
          <p>{location.street}</p>
          <p>{location.city}</p>
          <p>{location.county}</p>
          <p>{location.postcode}</p>
          <p>{location.state}</p>
          <p>{location.country}</p>
        </div>
        
        <div className="maps-button-container">
          <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer" className="maps-button">
            <img src={mapsIcon} alt="Open in Google Maps" />
            <span>Open in Google Maps</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Location;
