// src/components/edit-event/eventImageSave.js
/**
 * A simple function which saves an event image and its thumbnail to Firebase Storage, then returns the url that each image can be accessed by
 * @param {string} eventId - The event ID / firebase document ID, or blank if its a new event
 * @param {string} occurrenceDate - Optional - Date of the event occurrence to update, if blank then updates the entire series.
 * @param {object} images - Event data object (containing new image/thumbnail)
 * @returns {Promise<object>} - Resolves with an object containing the image and thumbnail URLs.
 * @throws {Error} - If image or thumbnail is missing or upload fails.
 */

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const eventImageSave = async (eventId, occurrenceDate, images) => {
  try {
    const storage = getStorage();

    if (!images || !images.eventImage || !images.thumbnailImage) {
      throw new Error("Both eventImage and thumbnailImage are required.");
    }

    const { eventImage, thumbnailImage } = images;
    let imageStorageRef;
    let thumbnailStorageRef;
    let imageUrl;
    let thumbnailUrl;

    // Save image for entire event
    if (!occurrenceDate) {
      imageStorageRef = ref(storage, `events/${eventId}/eventImage`);
      thumbnailStorageRef = ref(storage, `events/${eventId}/thumbnailImage`);
    }
    // Save image for a single occurrence
    else {
      imageStorageRef = ref(storage, `events/${eventId}/${occurrenceDate}_eventImage`);
      thumbnailStorageRef = ref(storage, `events/${eventId}/${occurrenceDate}_thumbnailImage`);
    }

    // Upload the images concurrently for better performance
    const [imageUpload, thumbnailUpload] = await Promise.all([
      uploadBytes(imageStorageRef, eventImage),
      uploadBytes(thumbnailStorageRef, thumbnailImage),
    ]);

    // Get the download URLs concurrently
    [imageUrl, thumbnailUrl] = await Promise.all([
        getDownloadURL(imageStorageRef),
        getDownloadURL(thumbnailStorageRef)
      ]);

    return { imageUrl, thumbnailUrl };
  } catch (error) {
    console.error("Error saving images to storage:", error);
    throw error; // Re-throw the error to be handled by the caller
  }
};

export default eventImageSave;