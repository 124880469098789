// src/components/edit-event/eventImagesFetch.js
// Descoped for now due to CORS issues. Need to install gsutil, or have a backend

import { getStorage, ref, getDownloadURL } from 'firebase/storage';

/**
 * Fetch an event's main image and thumbnail from Firebase Storage.
 * @param {firebase.app.App} app - The Firebase app instance.
 * @param {string} eventId - The event ID / Firebase document ID.
 * @returns {Promise<{imageFile: File | null, thumbnailFile: File | null}>} - Resolves with the image and thumbnail files, or null if an error occurs.
 */
async function fetchEventImages(app, eventId) {
  try {
    if (!app || !eventId) {
      throw new Error("Firebase app instance and event ID are required.");
    }

    const storage = getStorage(app);
    const imageRef = ref(storage, `events/${eventId}/eventImage`);
    const thumbnailRef = ref(storage, `events/${eventId}/thumbnailImage`);

    // Get signed URLs for the image and thumbnail
    const [imageUrl, thumbnailUrl] = await Promise.all([
      getDownloadURL(imageRef).catch(() => null), // Generate signed URL for eventImage
      getDownloadURL(thumbnailRef).catch(() => null), // Generate signed URL for thumbnailImage
    ]);

    // Fetch the Blobs from the signed URLs
const [imageBlob, thumbnailBlob] = await Promise.all([
    imageUrl 
      ? fetch(imageUrl, { mode: 'cors' }).then((res) => {
          if (!res.ok) throw new Error("Failed to fetch image Blob");
          return res.blob();
        }).catch(() => null)
      : null,
    thumbnailUrl 
      ? fetch(thumbnailUrl, { mode: 'cors' }).then((res) => {
          if (!res.ok) throw new Error("Failed to fetch thumbnail Blob");
          return res.blob();
        }).catch(() => null)
      : null,
  ]);
  

    // Convert the Blobs to File objects
    const imageFile = imageBlob ? new File([imageBlob], 'eventImage.jpg', { type: imageBlob.type }) : null;
    const thumbnailFile = thumbnailBlob ? new File([thumbnailBlob], 'thumbnailImage.jpg', { type: thumbnailBlob.type }) : null;

    return { imageFile, thumbnailFile };
  } catch (error) {
    console.error("Error fetching event images:", error);
    return { imageFile: null, thumbnailFile: null };
  }
}

export default fetchEventImages;
