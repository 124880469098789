// src/components/new-event/children/Priceslot.js
import React, { useState, useEffect } from 'react';
import SubHeader from '../../layout/SubHeader';
import SlideUpModal from '../../components/SlideUpModal';
import Input from '../../components/Input';
import AddItem from '../../components/AddItem';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import './Timeslot.css';

const Priceslot = ({ itemNumber, onDelete, onCopy, onUpdate, initialData, onMoveUp, onMoveDown }) => {
  const [closeOptionsModal, setCloseOptionsModal] = useState(false);

  const [itemName, setItemName] = useState(initialData?.itemName || '');
  const [selectedDiscounts, setSelectedDiscounts] = useState(() => {
    console.log("Object.keys(initialData.prices) ", Object.keys(initialData.prices))
    console.log("initialData.prices :", initialData.prices)
    if (Object.keys(initialData.prices).length > 0) {
      return Object.keys(initialData.prices); // Extract keys from prices
    }
    return ['Standard']; // Default if prices is not defined
  });
  const [prices, setPrices] = useState(initialData?.prices || {});
  const [sort, setSort] = useState(initialData?.sort || {});
  const [showTicketTypeModal, setShowTicketTypeModal] = useState(false);

  const discountOptions = ['Standard', 'Student', 'Concession', 'Performer'];

  // Debounce update effect to prevent infinite loop
  useEffect(() => {
    const updateTimeout = setTimeout(() => {
      onUpdate({
        id: itemNumber,
        itemName,
        selectedDiscounts,
        prices,
        sort,
      });
    }, 300);

    return () => clearTimeout(updateTimeout);
  }, [itemName, selectedDiscounts, prices, sort, itemNumber, onUpdate]);

  const handleDiscountSelection = (selectedDiscounts) => {
    const updatedPrices = { ...prices };
  
    // Remove prices for deselected discounts
    Object.keys(updatedPrices).forEach((discount) => {
      if (!selectedDiscounts.includes(discount)) {
        delete updatedPrices[discount];
      }
    });
  
    // Add empty prices for newly selected discounts
    selectedDiscounts.forEach((discount) => {
      if (!updatedPrices[discount]) {
        updatedPrices[discount] = '';
      }
    });
  
    setPrices(updatedPrices);
    setSelectedDiscounts(selectedDiscounts);
  };

  const handlePriceChange = (discountType, value) => {
    setPrices({
      ...prices,
      [discountType]: value,
    });
  };

  const handleAddDiscount = () => setShowTicketTypeModal(true);
  const handleCloseTicketTypeModal = () => setShowTicketTypeModal(false);

  return (
    <div className="timeslot-card layout">
      <SubHeader
        title={`Priced item`}
        // title={`Item ${itemNumber}`}
        modalContent={
          <>
            <Button 
              label="Move up" 
              variant="primary" 
              onClick={() => {
                onMoveUp();
                setCloseOptionsModal(true);
              }}
            />
            <Button 
              label="Move down" 
              variant="primary" 
              onClick={() => {
                onMoveDown();
                setCloseOptionsModal(true);
              }}
            />
            <Button 
              label="Delete" 
              variant="primary" 
              onClick={onDelete} 
            />
            <Button 
              label="Copy" 
              variant="primary" 
              onClick={() => {
                onCopy();
                setCloseOptionsModal(true);
              }}
            />
          </>
        }
        onCloseModal={closeOptionsModal}
      />
      <Input
        type="text"
        placeholder="e.g. 1 lesson, 2 lessons, social"
        value={itemName}
        onChange={(e) => setItemName(e.target.value)}
      />
  
      <div className="input-row-container">
        {selectedDiscounts.map((discount) => (
          <div key={discount} className="input-row">
            <label className="input-row-label">{`${discount}`}</label>
            <Input
              type="price"
              placeholder="0.00"
              value={prices[discount]}
              onChange={(e) => handlePriceChange(discount, e.target.value)}
            />
          </div>
        ))}
      </div>
  
      <AddItem text="Ticket type (optional)" onClick={handleAddDiscount} />
      <SlideUpModal
        isOpen={showTicketTypeModal}
        onClose={handleCloseTicketTypeModal}
        title="Ticket type"
        primaryActionLabel="Done"
        onPrimaryAction={handleCloseTicketTypeModal}
      >
        <div className="checkbox-list">
          <Checkbox
            labels={discountOptions}
            selectedLabels={selectedDiscounts}
            onSelectionChange={handleDiscountSelection}
          />
        </div>
      </SlideUpModal>
    </div>
  );  
};

export default Priceslot;
