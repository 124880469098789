// src/components/user/Feedback.js
import React from 'react';

const Feedback = () => (
  <div className='page-content textBlock'>
    <p>This app is still in Beta</p>
    <ul>
        <li>Bugs are currently being resolved</li>
        <li>New features are in progress</li>
    </ul>
    <p>If you do notice any issues with this app or want to suggest some changes, please send an email to the below address, including details of the suggestion or bug.</p>
    <a className='link' href="mailto:find.your.rhythm@hotmail.com">find.your.rhythm@hotmail.com</a>
  </div>
);

export default Feedback;
