// src/components/new-event/Agenda.js
import React, { useState, useEffect } from 'react';
import SegmentedSwitch from '../components/SegmentedSwitch';
import Button from '../components/Button';
import Timeslot from './children/Timeslot';
import iconPlusBrand from '../../assets/icons/icon-plus-brand.png';

const Agenda = ({ timeslots, setTimeslots, eventDays, eventDate }) => {
    const [selectedTab, setSelectedTab] = useState(0); // 0 for Timetable, 1 for Image


    useEffect(() => {
      // Ensure all timeslots have a date within the event date range
      setTimeslots((prevTimeslots) => 
          prevTimeslots.map((slot) => {
              // Check if the timeslot date is not in the updated days
              if (eventDays?.length > 0 && !eventDays.includes(slot.date)) {
                  return { ...slot, date: eventDate }; // Reset date to eventDate
              }
              return slot; // Keep the slot unchanged if the date is valid
          })
      );
  }, [eventDays, eventDate, setTimeslots]);
  

  const handleAddSlot = (date) => {
    setTimeslots((prevTimeslots) => {
      const lastSlot = prevTimeslots[prevTimeslots.length - 1]; // Get the last element
  
      return [
        ...prevTimeslots,
        {
          id: prevTimeslots.length + 1,
          slotName: '',
          startTime: lastSlot ? lastSlot.startTime : '19:00',
          endTime: lastSlot ? lastSlot.endTime : '20:00',
          levels: [],
          ...(eventDays?.length > 0 ? {date} : { date: date || null }),
        },
      ];
    });
  };

    const handleDeleteSlot = (id) => {
        setTimeslots((prevTimeslots) =>
            prevTimeslots.filter((slot) => slot.id !== id)
        );
    };

    const handleCopySlot = (id) => {
        const slotToCopy = timeslots.find((slot) => slot.id === id);
        if (slotToCopy) {
            setTimeslots((prevTimeslots) => [
                ...prevTimeslots,
                { ...slotToCopy, id: prevTimeslots.length + 1 },
            ]);
        }
    };

    const handleUpdateSlot = (updatedSlot) => {
        setTimeslots((prevTimeslots) =>
            prevTimeslots.map((slot) =>
                slot.id === updatedSlot.id ? updatedSlot : slot
            )
        );
    };

    // Sort timeslots by startTime (empty timeslots go last)
    const sortedTimeslots = [...timeslots].sort((a, b) => {
        const timeA = a.startTime || '24:00';
        const timeB = b.startTime || '24:00';
        return timeA.localeCompare(timeB);
    });

    return (
        <div className="layout">
            <h2>Agenda</h2>
            <SegmentedSwitch
                options={['Timetable', 'Image']}
                selected={selectedTab}
                onSelect={setSelectedTab}
            />
            {selectedTab === 0 && (
                <div className="timetable-section layout top-margin">
                    {eventDays?.length > 0
                        ? // Display timeslots grouped by days
                          eventDays.map((day) => (
                              <div key={day} className="day-section spacer-medium">
                                  <h3 className="spacer-medium">{day}</h3>
                                  {sortedTimeslots
                                      .filter((slot) => slot.date === day)
                                      .map((slot) => (
                                          <Timeslot
                                              key={slot.id}
                                              slotNumber={slot.id}
                                              initialData={slot}
                                              onDelete={() => handleDeleteSlot(slot.id)}
                                              onCopy={() => handleCopySlot(slot.id)}
                                              onUpdate={handleUpdateSlot}
                                          />
                                      ))}
                                  <Button
                                      label="Add slot"
                                      icon={iconPlusBrand}
                                      variant="secondary"
                                      onClick={() => handleAddSlot(day)}
                                  />
                              </div>
                          ))
                        : // Display all timeslots if no specific days
                          sortedTimeslots.map((slot) => (
                              <Timeslot
                                  key={slot.id}
                                  slotNumber={slot.id}
                                  initialData={slot}
                                  onDelete={() => handleDeleteSlot(slot.id)}
                                  onCopy={() => handleCopySlot(slot.id)}
                                  onUpdate={handleUpdateSlot}
                              />
                          ))}
                    {!eventDays || !eventDays.length > 0 && (
                        <Button
                            label="Add slot"
                            icon={iconPlusBrand}
                            variant="secondary"
                            onClick={() => handleAddSlot(eventDate)}
                        />
                    )}
                </div>
            )}
            {selectedTab === 1 && (
                <div className="image-section layout top-margin">
                    {eventDays?.length > 0
                        ? // Display disabled buttons grouped by days
                        eventDays.map((day) => (
                              <div key={day} className="day-section spacer-medium">
                                  <h3 className="spacer-medium">{day}</h3>
                                  <Button
                                      label="Add image"
                                      icon={iconPlusBrand}
                                      variant="secondary"
                                      disabled
                                  />
                              </div>
                          ))
                        : // Single button for events with no days
                          <Button
                              label="Add image"
                              icon={iconPlusBrand}
                              variant="secondary"
                              disabled
                          />}
                    <p>Feature coming soon!</p>
                </div>
            )}
        </div>
    );
};

export default Agenda;