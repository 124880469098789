// src/components/menu/addFavourite.js
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

/**
 * @param {string} eventId - The event ID / firebase document ID.
 * @param {string} action - Whether to add or remove the event ('add' or 'remove')
 */
const addFavourite = async (eventId, action) => {
  try {
    // Get the current user's ID from localStorage
    const userId = localStorage.getItem('userId');

    // Get current favourites from localStorage
    const eventFavourites = JSON.parse(localStorage.getItem('eventFavourites')) || [];
    
    // Modify the favourites array locally based on the action
    if (action === 'add' && !eventFavourites.includes(eventId)) {
      eventFavourites.push(eventId);
    } else if (action === 'remove') {
      const index = eventFavourites.indexOf(eventId);
      if (index > -1) {
        eventFavourites.splice(index, 1);
      }
    } else {
      console.error(`Invalid action: ${action}`);
      return;
    }

    // Update localStorage with the modified array
    localStorage.setItem('eventFavourites', JSON.stringify(eventFavourites));
    console.log(`Favourites updated locally: ${eventFavourites}`);

    // Update Firebase if userId exists
    if (userId) {
      const userDocRef = doc(db, 'users', userId);

      if (action === 'add') {
        await updateDoc(userDocRef, { eventFavourites: arrayUnion(eventId) });
      } else if (action === 'remove') {
        await updateDoc(userDocRef, { eventFavourites: arrayRemove(eventId) });
      }
      console.log(`Favourites also updated in Firebase for user ${userId}`);
    }
  } catch (error) {
    console.error('Error updating favourites:', error);
  }
};

export default addFavourite;
