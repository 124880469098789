// src/components/edit-event/eventDelete.js
/**
 * Function to handle deletion of event and its images, including removing it from local storage
 * @param {string} eventId - The event ID / firebase document ID.
 * @returns {Promise<void>} - Resolves when the action is complete.
 */

import { getFirestore, doc, deleteDoc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { db, storage } from '../../firebaseConfig'; // import storage
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import { openDB } from 'idb'; // Import for IndexedDB access

const deleteEvent = async (eventId) => {
  if (!eventId) {
    throw new Error("Event ID is required.");
  }

  try {
    // Reference to the event document in Firestore
    const eventDocRef = doc(db, "events", eventId);

    // Reference to the event's storage folder in Firebase Storage
    const storageFolderRef = ref(storage, `events/${eventId}`);
    
    // List and delete all files in the event's storage folder
    const { items } = await listAll(storageFolderRef);
    const deletePromises = items.map((item) => deleteObject(item));
    await Promise.all(deletePromises);

    console.log("All associated images deleted from Firebase Storage.");

    // Delete the event document from Firestore
    await deleteDoc(eventDocRef);
    console.log('Event with ID ${eventId} deleted successfully.');

    // Delete the event from local IndexedDB
    await deleteLocalEvent(eventId);

  } catch (error) {
    console.error('Failed to delete event with ID ${eventId}:, error');
    throw error;
  }
};

// Delete the event from local storage
const deleteLocalEvent = async (eventId) => {
  try {
    const db = await openDB('EventDB', 1); // Open the database (version 1)

    // Check if the database exists
    if (!db.objectStoreNames.contains('events')) {
      console.warn('Object store "events" does not exist in the database.');
      return; // Or throw an error if you want to enforce the store's existence
    }

    const tx = db.transaction('events', 'readwrite'); // Start a readwrite transaction
    const store = tx.objectStore('events');

    // Check if the event exists before attempting to delete it
    const existingEvent = await store.get(eventId);
    if (!existingEvent) {
      console.warn('Event with ID ${eventId} does not exist in local storage.');
      return; // Or throw an error
    }

    await store.delete(eventId); // Delete the event

    await tx.done; // Wait for the transaction to complete

    console.log('Event with ID ${eventId} deleted from local storage.');

  } catch (error) {
    console.error('Error deleting event from local storage:', error);
    // Handle the error appropriately in your component
    throw error; // Re-throw the error so the calling component can handle it
  }
};

export default deleteEvent;