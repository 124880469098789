// src/components/maintenance/LocalStorageSync.js
// Syncs current user's data between local storage and firestore
import React, { useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

const LocalStorageSync = ({ userId, displayName = '' }) => {
  
  const getLocalData = () => ({
    selectedCounties: JSON.parse(localStorage.getItem('selectedCounties')) || [],
    selectedDances: JSON.parse(localStorage.getItem('selectedDances')) || [],
    eventCreator: JSON.parse(localStorage.getItem('eventCreator')) || [],
    // permission: JSON.parse(localStorage.getItem('permission')) || '',  // Don't care what it is, we'll overwrite it regardless
    eventFavourites: JSON.parse(localStorage.getItem('eventFavourites')) || []
  });

  const saveToLocal = (data) => {
    localStorage.setItem('selectedCounties', JSON.stringify(data.selectedCounties || []));
    localStorage.setItem('selectedDances', JSON.stringify(data.selectedDances || []));
    localStorage.setItem('eventCreator', JSON.stringify(data.eventCreator || []));
    localStorage.setItem('eventFavourites', JSON.stringify(data.eventFavourites || []));
    if (data.permission) {
      localStorage.setItem('permission', data.permission);
    } else {
      localStorage.removeItem('permission');
    }
  };

  const mergeData = (localData, firebaseData) => ({
    selectedCounties: Array.from(new Set([...localData.selectedCounties, ...(firebaseData.selectedCounties || [])])),
    selectedDances: Array.from(new Set([...localData.selectedDances, ...(firebaseData.selectedDances || [])])),
    eventCreator: Array.from(new Set([...localData.eventCreator, ...(firebaseData.eventCreator || [])])),
    eventFavourites: Array.from(new Set([...localData.eventFavourites, ...(firebaseData.eventFavourites || [])])),
    ...(firebaseData.permission ? { permission: firebaseData.permission } : {})
  });
  

  const syncUserData = async () => {
    if (!userId) return;

    const userDocRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userDocRef);
    const localData = getLocalData();

    const firebaseData = userSnapshot.data() || {}; // Handle cases where document doesn't exist

    // Add or update displayName in firebaseData
    firebaseData.displayName = displayName;

    if (userSnapshot.exists()) {
      const mergedData = mergeData(localData, firebaseData);
      saveToLocal(mergedData);
      await updateDoc(userDocRef, firebaseData); // Update with firebaseData which now includes displayName
    } else {
      await setDoc(userDocRef, { ...localData, displayName }); // Include displayName in new document
    }
  };

  useEffect(() => {
    syncUserData();
  }, [userId]);

  return null; // This component does not render anything
};

export default LocalStorageSync;
