// src/components/TopNav.js
import React from 'react';
import './TopNav.css';

const TopNav = ({ tabs, setActiveTab, activeTab }) => {
  return (
    <nav className="top-nav">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={activeTab === tab.id ? 'active-tab' : ''}
          onClick={() => setActiveTab(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </nav>
  );
};

export default TopNav;
