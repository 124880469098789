// src/components/new-event/Event.js
import React, { useState, useRef, useEffect } from 'react';
import AddItem from '../components/AddItem';
import SlideUpModal from '../components/SlideUpModal';
import Tag from '../components/Tag';
import SegmentedSwitch from '../components/SegmentedSwitch';
import Checkbox from '../components/Checkbox';  // Custom Checkbox component
import Input from '../components/Input';
import { listOfDanceTypes } from '../../data/danceTypes'; // Import dance types
import calculateEventDays from '../events/eventDays'; // Function to return days between start and end dates

const Event = ({
  danceTypes,
  setDanceTypes,
  isRecurring,
  setIsRecurring,
  selectedDay,
  setSelectedDay,
  eventDate,
  setEventDate,
  eventEndDate,
  setEventEndDate,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  eventDays,
  setEventDays,
  restrictedEdit = false // True if editing a single occurrence
}) => {
  const [showDanceModal, setShowDanceModal] = useState(false);

  // Toggle modal visibility
  const handleAddDance = () => setShowDanceModal(true);
  const handleCloseModal = () => setShowDanceModal(false);


  // Validate end time/date to be after start time/date
  useEffect(() => {
    if (!eventEndDate || 
        eventEndDate == '' || 
        eventEndDate < eventDate)
        {
      setEventEndDate(eventDate);
    }
    if((eventDate == eventEndDate) && (startTime > endTime)) {
      setEndTime(startTime);
    }
  }, [eventDate, eventEndDate, endTime, startTime]);


  // Create eventDays value to include all unique days within the event
  useEffect(() => {
    const convertToMinutes = (timeStr) => {
      const [hours, minutes] = timeStr.split(':').map(Number);
      return hours * 60 + minutes;
  };
  
  if (eventEndDate && eventDate) { 
      const eventDaysList = calculateEventDays(eventDate, eventEndDate); // Get days as an array of strings (yyyy-mm-dd)
      if (convertToMinutes(endTime) < convertToMinutes("06:01")) {
        // Remove the last day from this list if its not actually a day, e.g. finishes at 4am
        eventDaysList.pop();
      }
      setEventDays(eventDaysList);
    }
  
    if (isRecurring == true){
      // Clear the values of eventDays
      setEventDays([]);
    }
  }, [eventDate, eventEndDate, endTime, startTime, isRecurring]);


  return (
    <div className="layout">
      <h2>Dance type</h2>

      {danceTypes.length > 0 && (
        <div className="tag-container">
          {danceTypes.map((dance, index) => (
            <Tag
              key={index}
              label={dance}
              dismissible
              onClick={() => setDanceTypes(danceTypes.filter((type) => type !== dance))}
            />
          ))}
        </div>
      )}
      <AddItem text="Dances" onClick={handleAddDance} />

      {/* Dance Type Modal */}
      <SlideUpModal
        isOpen={showDanceModal}
        onClose={handleCloseModal}
        title="Dance type"
        primaryActionLabel="Done"
        onPrimaryAction={handleCloseModal}
      >
        <div className="checkbox-list">
          <p className="bottom-margin">Select the main types of dance for your event.</p>
          <Checkbox
            labels={listOfDanceTypes}
            selectedLabels={danceTypes}
            onSelectionChange={setDanceTypes}
          />
        </div>
      </SlideUpModal>

      <h2>When</h2>
      <SegmentedSwitch
        options={['Weekly', 'One-off']}
        selected={isRecurring ? 0 : 1}  // Set the selected index based on isRecurring
        onSelect={(index) => setIsRecurring(index === 0)}
        disabled={restrictedEdit} // Disabled if editing a single occurrence
      />

      {isRecurring ? (
        <div>
        <SegmentedSwitch
          options={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
          selected={selectedDay}  // Ensure the selectedDay is passed correctly
          onSelect={setSelectedDay}
          disabled={restrictedEdit} // Disabled if editing a single occurrence
        />
        <div className="time-inputs top-margin ">
        <Input
          label="Start Time"
          type="time"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
        />
        <p>to</p>
        <Input
          label="End Time"
          type="time"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
        />
      </div>
        </div>

      ) : (
        <div>
        <p>Event start</p>
        <div className="time-inputs">
        <Input
          label="Event Date"
          type="date"
          value={eventDate}
          onChange={(e) => setEventDate(e.target.value)}
        />
        <Input
          label="Start Time"
          type="time"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
        />
        </div>
        <p>Event end</p>
        <div className="time-inputs">
        <Input
          label="Event End Date"
          type="date"
          value={eventEndDate}
          onChange={(e) => setEventEndDate(e.target.value)}
        />
        <Input
          label="End Time"
          type="time"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
        />
        </div>
      </div>
      )}

    </div>
  );
};

export default Event;
