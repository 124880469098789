// src/pages/ViewEvent.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import eventStorage from '../components/events/eventStorage';
import eventFetchSingle from '../components/events/eventFetchSingle';
import Button from '../components/components/Button';
import backIcon from '../assets/icons/icon-previous-white.png';
import starIcon from '../assets/icons/icon-star-brand.png';
import starIconSelected from '../assets/icons/icon-starfill-white.png';
import priceIcon from '../assets/icons/icon-price-white.png';
import menuIcon from '../assets/icons/icon-menu-brand.png';
import locationIcon from '../assets/icons/tag-location.png';
import PageFooter from '../components/layout/PageFooter';
import imagePlaceholder from '../assets/icons/placeholder.png';
import TopNav from '../components/layout/TopNav';
import About from '../components/view-event/About';
import Agenda from '../components/view-event/Agenda';
import Pricing from '../components/view-event/Pricing';
import Location from '../components/view-event/Location';
import EventOptions from '../components/edit-event/EventOpions';
import addFavourite from '../components/menu/addFavourite';
import './ViewEvent.css';

const ViewEvent = () => {
  const { eventId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const occurrenceDate = query.get('date'); // Occurrence date from the URL - use event.occurrenceDate when possible
  const userId = localStorage.getItem('userId');

  const [eventData, setEventData] = useState(null);
  const [activeTab, setActiveTab] = useState('about');
  const [isEventCreator, setIsEventCreator] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [favouriteButton, setFavouriteButton] = useState("secondary");
  const [favouriteIcon, setFavouriteIcon] = useState(starIcon);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [eventDataChanged, setEventDataChanged] = useState(0); // Used to retrigger eventFetch
  const navigate = useNavigate();

  const checkIfFavourite = (eventId) => {
    const eventFavourites = JSON.parse(localStorage.getItem('eventFavourites')) || [];
    return eventFavourites.includes(eventId);
  };

  
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const filters = { eventId };
        const event = await eventStorage({
          filters,
          onlyDances: false,
          onlyCounties: false,
          onlyApproved: false,
          occurrenceDate,
          // eventSeries: true, // This can be used to get the entire event instead of one exception/date
        });
        setEventData(event[0]);
        console.log('Fetched events:', event);
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };
  
    const fetchData = async () => {
      try {
        // Wait for eventFetchSingle to complete
        await eventFetchSingle(eventId);
        // Then call fetchEvent to get it from local storage with exceptions
        await fetchEvent();
      } catch (error) {
        console.error('Error in fetchData:', error);
      }
    };
  
    fetchData();
  }, [eventId, occurrenceDate, eventDataChanged]);

  // Function to refresh the event data if anything changes
  const refreshEventData = () => {
    setEventDataChanged(eventDataChanged + 1); // Changes value to re-trigger `fetchData`
    // Alternatively, refresh the page
    // window.location.reload(); 
  };

  useEffect(() => {
    if (eventData) {
      setIsEventCreator(userId === eventData.creator); // Check if user is the owner of the event

      if (checkIfFavourite(eventId)) {
        setFavouriteButton('primary');
        setFavouriteIcon(starIconSelected);
        setIsFavourite(true);
      } else {
        setFavouriteButton('secondary');
        setFavouriteIcon(starIcon);
        setIsFavourite(false);
      }
    }
  }, [eventData]);

  if (!eventData) {
    console.log("No event data. eventData :", eventData);
    return <div>Loading...</div>;
  }

  const formatDate = (date) => {
    const options = { weekday: 'short', day: 'numeric', month: 'short' };
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('en-GB', options);
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(hours, minutes);
    return date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true });
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'about':
        return <About 
        announcementText={eventData.announcementText} 
        approved={eventData.approved}
        description={eventData.description} 
        team={eventData.team} 
        />;
      case 'agenda':
        return <Agenda 
        agenda={eventData.agenda} 
        />;
      case 'pricing':
        return <Pricing 
        pricing={eventData.pricing} 
        />;
      case 'location':
        return <Location 
        location={eventData.location} 
        />;
      default:
        return null;
    }
  };

  const handleBackButtonClick = () => {
    // Navigate the user when they click back icon
    const previousScreen = location.state?.from;

    if (['/create-event', '/edit-event', '/manageevents'].includes(previousScreen)) {
      navigate('/manageevents'); // Redirect to manage events screen
    } else if (['/events', '/calendar', '/myevents', '/profile'].includes(previousScreen)) {
      navigate(-1); // Go back to the previous page
    }
    else {
      navigate('/'); // Redirect to home screen
    }
  };

  const handleFavourite = () => {
    if (isFavourite) {
      setFavouriteButton('secondary');
      setFavouriteIcon(starIcon);
      setIsFavourite(false);
      addFavourite(eventId, 'remove');
    } else {
      setFavouriteButton('primary');
      setFavouriteIcon(starIconSelected);
      setIsFavourite(true);
      addFavourite(eventId, 'add');
    }
  };

  const footerButtons = [
    {
      component: (
        <Button
          variant={favouriteButton}
          label="Favourite"
          icon={favouriteIcon}
          onClick={() => handleFavourite()}
        />
      ),
    },
    {
      component: (
        <Button
          variant="primary"
          label="Tickets"
          disabled={true}
          icon={priceIcon}
        />
      ),
    },
    ...(isEventCreator
      ? [
          {
            component: (
              <Button
                variant="secondary"
                icon={menuIcon}
                onClick={() => setIsOptionsModalOpen(true)}
              />
            ),
          },
        ]
      : []),
  ];

  const DateHeader = () => {
    if (eventData.isRecurring || eventData.eventDate === eventData.eventEndDate) {
      // Event only has one date. Display eventDate + startTime/endTime
      return (
        <>
          {formatDate(eventData.occurrenceDate || eventData.eventDate)} <span className="separator">•</span>
              {formatTime(eventData.startTime)}
              {eventData.endTime ? ` - ${formatTime(eventData.endTime)}` : ''}
        </>
      );
    } else {
      // Event needs to display eventDate and eventEndDate
      return (
        <>
          {formatDate(eventData.eventDate)} <span className="separator">•</span> {formatTime(eventData.startTime)} <br />
          -  {formatDate(eventData.eventEndDate)} {eventData.endTime ? <><span className="separator">•</span> {formatTime(eventData.endTime)}</> : ''}
        </>
      );
    }
  };

  return (
    <div className="view-event-container">
      <div className="floatingButton">
        <Button variant="icon-only" icon={backIcon} onClick={handleBackButtonClick} />
      </div>

      <div className="event-header">
        <div className="event-image-container">
          <img
            src={eventData.imageUrl || imagePlaceholder}
            alt={eventData.eventName}
            className="event-image"
          />
        </div>
      </div>

      <div className="scrollable-page">
        <div className="header-spacer"></div>

        <div className="event-details-container">
          <div className="event-details-header">
            <h1>
              {eventData.isCancelled && <span className="cancelled-text">Cancelled : </span>}
              {eventData.eventName}
            </h1>
            <p className="viewevent-time">
              <DateHeader />
            </p>
            <span className="viewevent-location">
              <p>
                <img src={locationIcon} alt="Location" /> {eventData.location.venue} •{' '}
                {eventData.location.county || eventData.location.street} • {eventData.location.postcode}
              </p>
            </span>

            <TopNav
              tabs={[
                { id: 'about', label: 'About' },
                { id: 'agenda', label: 'Agenda' },
                { id: 'pricing', label: 'Pricing' },
                { id: 'location', label: 'Location' },
              ]}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </div>

          <div className="viewevent-content">{renderTabContent()}</div>
        </div>
      </div>

      <EventOptions
        isOpen={isOptionsModalOpen}
        onClose={() => setIsOptionsModalOpen(false)}
        eventId={eventId}
        exceptionIndex={eventData.exceptionIndex}
        occurrenceDate={eventData.occurrenceDate || eventData.eventDate || occurrenceDate}
        isRecurring={eventData.isRecurring}
        isCancelled={eventData.isCancelled}
        announcementText={eventData.announcementText}
        refreshEventData={refreshEventData}
      />

      <PageFooter buttons={footerButtons} />
    </div>
  );
};

export default ViewEvent;
