// src/components/components/UploadContainer.js
import React, { useRef, useState, useEffect } from 'react';
import './UploadContainer.css';
import iconPlusBrand from '../../assets/icons/icon-plus-brand.png';

const UploadContainer = ({ setFile, previewUrl, setPreviewUrl, fileTypes = 'image/jpeg, image/png', placeholderText = 'Upload file', setThumbnailFile, scalingMode = 'shrink' }) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null); // Store the file in state

  const processImage = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const fullSizeWidth = 360;
        const fullSizeHeight = 240;
        canvas.width = fullSizeWidth;
        canvas.height = fullSizeHeight;

        if (scalingMode === 'crop') {
          const aspectRatio = Math.max(fullSizeWidth / img.width, fullSizeHeight / img.height);
          const scaledWidth = img.width * aspectRatio;
          const scaledHeight = img.height * aspectRatio;
          const offsetX = (fullSizeWidth - scaledWidth) / 2;
          const offsetY = (fullSizeHeight - scaledHeight) / 2;
          ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);
        } else { // 'shrink' mode
          const widthRatio = img.width / fullSizeWidth;
          const heightRatio = img.height / fullSizeHeight;
          const maxRatio = Math.max(widthRatio, heightRatio);
          const scaledWidth = img.width / maxRatio;
          const scaledHeight = img.height / maxRatio;
          const offsetX = (fullSizeWidth - scaledWidth) / 2;
          const offsetY = (fullSizeHeight - scaledHeight) / 2;
          ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);
        }

        canvas.toBlob((blob) => {
          const scaledFile = new File([blob], file.name, { type: file.type });
          setFile(scaledFile);
          const previewUrl = URL.createObjectURL(blob);
          setPreviewUrl(previewUrl);
        }, file.type);

        const thumbnailCanvas = document.createElement('canvas');
        const thumbnailCtx = thumbnailCanvas.getContext('2d');
        const thumbnailWidth = 300;
        const thumbnailHeight = 200;
        thumbnailCanvas.width = thumbnailWidth;
        thumbnailCanvas.height = thumbnailHeight;

        if (scalingMode === 'crop') {
          const thumbnailAspectRatio = Math.max(thumbnailWidth / img.width, thumbnailHeight / img.height);
          const thumbnailScaledWidth = img.width * thumbnailAspectRatio;
          const thumbnailScaledHeight = img.height * thumbnailAspectRatio;
          const thumbnailOffsetX = (thumbnailWidth - thumbnailScaledWidth) / 2;
          const thumbnailOffsetY = (thumbnailHeight - thumbnailScaledHeight) / 2;
          thumbnailCtx.drawImage(img, thumbnailOffsetX, thumbnailOffsetY, thumbnailScaledWidth, thumbnailScaledHeight);
        } else { // 'shrink' mode
          const thumbnailWidthRatio = img.width / thumbnailWidth;
          const thumbnailHeightRatio = img.height / thumbnailHeight;
          const maxThumbnailRatio = Math.max(thumbnailWidthRatio, thumbnailHeightRatio);
          const thumbnailScaledWidth = img.width / maxThumbnailRatio;
          const thumbnailScaledHeight = img.height / maxThumbnailRatio;
          const thumbnailOffsetX = (thumbnailWidth - thumbnailScaledWidth) / 2;
          const thumbnailOffsetY = (thumbnailHeight - thumbnailScaledHeight) / 2;
          thumbnailCtx.drawImage(img, thumbnailOffsetX, thumbnailOffsetY, thumbnailScaledWidth, thumbnailScaledHeight);
        }

        thumbnailCanvas.toBlob((thumbnailBlob) => {
          const thumbnailFile = new File([thumbnailBlob], file.name.replace(/\.[^/.]+$/, "_thumbnail.jpg"), { type: 'image/jpeg' });
          setThumbnailFile(thumbnailFile);
        }, 'image/jpeg');
      };
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setSelectedFile(file); // Update selected file
  };

  useEffect(() => {
    if (selectedFile) {
      processImage(selectedFile); // Process image when scaling mode or file changes
    }
  }, [scalingMode, selectedFile]);

  const handleRemoveFile = () => {
    setPreviewUrl('');
    setFile(null);
    setThumbnailFile(null);
    setSelectedFile(null);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="upload-container" onClick={handleClick}>
      {!previewUrl && (
        <div className="upload-placeholder">
          <img src={iconPlusBrand} alt="Upload icon" />
          <p>{placeholderText}</p>
        </div>
      )}
      {previewUrl && (
        <div className="image-preview">
          <img src={previewUrl} alt="Preview" />
          <button
            className="remove-button"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveFile();
            }}
          >
            Remove file
          </button>
        </div>
      )}
      <input
        id="upload-input"
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept={fileTypes}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default UploadContainer;
