// src/components/components/Tag.js
import React from 'react';
import './Tag.css';
import cancelWhiteIcon from '../../assets/icons/icon-cancel-white.png';
import cancelBrandIcon from '../../assets/icons/icon-cancel-brand.png';

const Tag = ({
  variant = 'primary',
  label = '',
  dismissible = false,
  size = 'medium',
  onClick = null,
}) => {
  const isPrimary = variant === 'primary';
  const dismissIcon = isPrimary ? cancelWhiteIcon : cancelBrandIcon;

  const tagClass = `tag tag-${variant} ${size} ${onClick ? 'clickable' : ''}`;

  return (
    <div className={tagClass} onClick={onClick ? onClick : null}>
      {label && <span className="tag-label">{label}</span>}
      {dismissible && (
        <span className="tag-dismiss">
          <img src={dismissIcon} alt="Dismiss" />
        </span>
      )}
    </div>
  );
};

export default Tag;
