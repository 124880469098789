// src/components/events/eventExceptions.js
// This component takes in an event and date then returns the event data for that specific date
const getNextOccurrenceOfDay = (day) => {
    const today = new Date();
    const currentDay = today.getDay();
    const daysToAdd = (day - currentDay + 7) % 7;
    const nextDate = new Date(today.setDate(today.getDate() + daysToAdd));
    return nextDate.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
  };
/**
 * Ensures the occurrence date aligns with the recurrence day or event date.
 * @param {Object} event - The event data.
 * @param {string} urlDate - Optional date passed in via URL.
 * @returns {string} The validated occurrence date.
 */
const validateOccurrenceDate = (event, urlDate) => {
  let occurrenceDate;

  if (event.isRecurring) {
    const recurrenceDay = parseInt(event.recurrenceDay); 
    if (urlDate) {
      const urlDateObj = new Date(urlDate);
      if (urlDateObj.getDay() === recurrenceDay) {
        occurrenceDate = urlDate; // URL date matches recurrence day
      } else {
        occurrenceDate = getNextOccurrenceOfDay(recurrenceDay); // Use next valid occurrence
      }
    } else {
      occurrenceDate = getNextOccurrenceOfDay(recurrenceDay); // No URL date, use next occurrence
    }
  } else {
    occurrenceDate = event.eventDate; // Non-recurring, use event date
  }

  return occurrenceDate;
};

/**
 * Applies exception overrides to an event for a specific occurrence date.
 * 
 * @param {Object} event - The base event object.
 * @param {string} urlDate - Optional date of the occurrence to check for exceptions.
 * @returns {Object} The event object with any exception overrides applied.
 */
const applyEventExceptions = (event, urlDate) => {
  // Validate the occurrence date first
//   console.log("Called applyEventExceptions")
  
  const occurrenceDate = validateOccurrenceDate(event, urlDate);

//   console.log(occurrenceDate)
//   console.log(event)
  const exception = event.exceptions?.find((ex) => ex.date === occurrenceDate);

  if (exception) {
    return {
      ...event,
      ...exception,
      eventName: exception.eventName || event.eventName,
      shortLocation: exception.shortLocation || event.shortLocation,
      description: exception.description || event.description,
      team: exception.team || event.team,
      danceTypes: exception.danceTypes || event.danceTypes,
      agenda: exception.agenda || event.agenda,
      pricing: exception.pricing || event.pricing,
      location: exception.location || event.location,
      venue: exception.location?.venue || event.location?.venue,
      eventDate: exception.eventDate || event.eventDate,
      eventEndDate: exception.eventEndDate || event.eventEndDate,
      eventDays: exception.eventDays || event.eventDays,
      isRecurring: event.isRecurring ?? false,
      recurrenceDay: exception?.recurrenceDay || event.recurrenceDay,
      startTime: exception.startTime || event.startTime,
      endTime: exception.endTime || event.endTime,
      imageUrl: exception?.imageUrl || event.imageUrl,
      thumbnailUrl: exception?.thumbnailUrl || event.thumbnailUrl,
      isCancelled: exception.isCancelled ?? event.isCancelled,
      announcementText: exception.announcementText || event.announcementText,
      exceptionIndex: event.exceptions
        ? event.exceptions.findIndex((ex) => ex.date === occurrenceDate)
        : null,
      occurrenceDate,
    };
  }

  // No exception found; return the event with the validated occurrence date.
  return { ...event, occurrenceDate };
};

export default applyEventExceptions;
