// src/components/event-display/EventCardLarge.js
import React, { useState } from 'react';
import './EventCardLarge.css';
import iconLocation from '../../assets/icons/icon_location.svg';
import iconAlert from '../../assets/icons/icon_alert.svg';
import placeholder from '../../assets/icons/placeholder_event_small.png';

const EventCardLarge = ({ event, onClick }) => {
  const {
    eventName,
    eventDate,
    eventEndDate = eventDate,
    thumbnailUrl,
    isCancelled,
    announcementText,
    location: { venue, county } = {},
    description,
  } = event || {};
  const [imageError, setImageError] = useState(false);

  // Format dates
  const formatDate = (date) =>
    new Date(date).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

  const dateText =
    eventDate === eventEndDate
      ? formatDate(eventDate)
      : `${formatDate(eventDate)} - ${formatDate(eventEndDate)}`;

  return (
    <div
      className={`event-card-large ${isCancelled ? 'cancelled' : ''}`}
      onClick={onClick}
    >
      {/* Announcement section */}
      {announcementText && (
        <div className="announcement-large">
          <h3>ANNOUNCEMENT</h3>
          <p>{announcementText}</p>
        </div>
      )}

      <div className="event-content-large">
        {/* Event name */}
        <h2 className="event-name-large">{eventName || 'Untitled Event'}</h2>

        {/* Event dates */}
        <div className="event-dates-large">{dateText}</div>

        {/* Event image */}
        {thumbnailUrl && (
          <div className="event-image-large">
          <img
            src={(!imageError && thumbnailUrl) || placeholder}
            alt={eventName || 'Event'}
            onError={() => setImageError(true)}
          />
        </div>
        )}

        {/* Event description */}
        <div className="event-description-large">
          {description?.length > 100
            ? `${description.slice(0, 100)}...`
            : description || ''}
        </div>

        {/* Event location */}
        <div className="event-location-large">
          <img src={iconLocation} alt="Location icon" className="icon-location" />
          <span>
            {venue || 'Venue not available'}
            {county ? `, ${county}` : ''}
          </span>
        </div>
      </div>

      {isCancelled && (
        <img src={iconAlert} alt="Alert: Event cancelled" className="icon-alert" />
      )}
    </div>
  );
};

export default EventCardLarge;
