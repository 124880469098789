// src/components/user/Avatar.js
import React from 'react';
import PropTypes from 'prop-types';
import './Avatar.css'; // Styles for the avatar

const Avatar = ({ name }) => {
  // Function to get initials from the name
  const getInitials = (name) => {
    const [firstName, lastName] = name.split(' ');
    return `${firstName[0]}${lastName ? lastName[0] : ''}`.toUpperCase();
  };

  return (
    <div className="avatar">
      {name ? (
        <span className="avatar-initials">{getInitials(name)}</span>
      ) : (
        <img
          src={require('../../assets/icons/useravatar.png')}
          alt="User Avatar"
          className="avatar-image"
        />
      )}
    </div>
  );
};

Avatar.propTypes = {
  name: PropTypes.string,
};

export default Avatar;
