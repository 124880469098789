// src/components/view-event/Agenda.js
import React from 'react';

const Agenda = ({ agenda }) => {
    // Check if agenda data is available
  if (!agenda || agenda.length === 0) {
    return (
      <div className="layout">
        <div className='viewevent-container'>
          <p>No timetable available</p>
        </div>
      </div>
    );
  }

    // Sort timeslots by startTime (empty timeslots go last)
    const sortedAgenda = [...agenda].sort((a, b) => {
        const timeA = a.startTime || '24:00';
        const timeB = b.startTime || '24:00';
        return timeA.localeCompare(timeB);
    });

    // Extract unique dates from the agenda
    const uniqueDates = [...new Set(sortedAgenda.map((slot) => slot.date))];

    // Display dates in a friendly way if multiple event days
    const formatDate = (date) => {
      const options = { weekday: 'short', day: 'numeric', month: 'short' };
      const dateObj = new Date(date);
      return dateObj.toLocaleDateString('en-GB', options);
    };

    return (
        <div className="layout">
            {uniqueDates.length > 1
                ? // Display timeslots grouped by date
                  uniqueDates.map((date) => (
                      <div key={date} className="day-section spacer-medium">
                          <h3 className="spacer-medium">{formatDate(date)}</h3>
                          {sortedAgenda
                              .filter((slot) => slot.date === date)
                              .map((slot, index) => (
                                <div key={index} className="viewevent-container agenda">
                                    <div className="agenda-overview">
                                        <h3>{slot.slotName}</h3>
                                        <p>
                                            {slot.startTime} - {slot.endTime}
                                        </p>
                                    </div>
                                    <div className="agenda-levels">
                                        {slot.levels?.map((level, idx) => (
                                            <span key={idx} className="level">
                                                {level}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                )
                              )}
                      </div>
                  ))
                : // Display all timeslots without grouping if only one date
                  sortedAgenda.map((slot, index) => (
                    <div key={index} className="viewevent-container agenda">
                        <div className="agenda-overview">
                            <h3>{slot.slotName}</h3>
                            <p>
                                {slot.startTime} - {slot.endTime}
                            </p>
                        </div>
                        <div className="agenda-levels">
                            {slot.levels?.map((level, idx) => (
                                <span key={idx} className="level">
                                    {level}
                                </span>
                            ))}
                        </div>
                    </div>
                    )
                  )}
        </div>
    );
};

export default Agenda;
