// src/components/PageFooter.js
// Footer with buttons
import React from 'react';
import './PageFooter.css';

const PageFooter = ({ buttons }) => {
  return (
    <footer className="page-footer">
      {buttons.map((button, index) => (
        <div
          key={index}
          className={`footer-button ${button.component.props.icon && !button.component.props.label ? 'icon-only' : ''}`}
          >
          {button.component}
        </div>
      ))}
    </footer>
  );
};

export default PageFooter;
