// src/components/edit-event/EventOptions.js
/**
 * Pop-up options for an event, such as Delete / Edit / Announcement 
 */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SlideUpModal from '../components/SlideUpModal';
import Button from '../components/Button';
import Input from '../components/Input';
import deleteEvent from './eventDelete';
import eventUpdate from './eventUpdate';

import announceIcon from '../../assets/icons/icon-announce-white.png';
import alertIcon from '../../assets/icons/icon-alert-white.png';
import editIcon from '../../assets/icons/icon-edit-white.png';
import cancelIcon from '../../assets/icons/icon-cancel-white.png';
import tickIcon from '../../assets/icons/icon-tick-white.png';
import copyIcon from '../../assets/icons/icon-copy-white.png';

const EventOptions = ({
  isOpen,
  onClose,
  eventId,
  exceptionIndex,
  occurrenceDate,
  isRecurring,
  isCancelled,
  announcementText,
  refreshEventData,
}) => {
  const navigate = useNavigate();
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [showCancelEventModal, setShowCancelEventModal] = useState(false);
  const [showReinstateEventModal, setShowReinstateEventModal] = useState(false);
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [newAnnouncementText, setNewAnnouncementText] = useState(announcementText || '');

  // Save updated event data
  const saveEventData = async (field, value) => {
    try {
      await eventUpdate(eventId, occurrenceDate, exceptionIndex, { [field]: value });
      onClose();
      refreshEventData(); // Refresh data or reload the page
    } catch (error) {
      console.error(`Error updating event ${field}:`, error);
    }
  };

  // Announcement Handlers
  const handleAnnouncementSave = async () => {
    await saveEventData('announcementText', newAnnouncementText);
    setShowAnnouncementModal(false);
  };

  // Cancellation Handlers
  const handleCancelEvent = async () => {
    await saveEventData('isCancelled', true);
    setShowCancelEventModal(false);
  };

  const handleReinstateEvent = async () => {
    await saveEventData('isCancelled', false);
    setShowReinstateEventModal(false);
  };

  // Button to add or change the event announcement
  const buttonAnnouncement = () => (
    <Button
      variant="primary"
      label={announcementText ? "Edit announcement" : "Make announcement"}
      icon={announceIcon}
      onClick={() => setShowAnnouncementModal(true)}
    />
  );

  // Modal to enter or edit announcement
  const confirmAnnouncement = () => (
    <SlideUpModal
      isOpen={showAnnouncementModal}
      onClose={() => setShowAnnouncementModal(false)}
      title="Announcement"
    >
      <div className="layout">
        <Input
          type="text"
          placeholder="Announcement (max 120 characters)"
          value={newAnnouncementText}
          onChange={(e) => setNewAnnouncementText(e.target.value)}
          showIcon={false}
          multiline={true}
        />
        <div style={{ marginBottom: '10px' }}></div>
        <Button
          variant="primary"
          label="Save"
          icon={tickIcon}
          onClick={handleAnnouncementSave}
        />
      </div>
    </SlideUpModal>
  );

// Button to edit a single event occurrence
const buttonEditOccurrence = () => {
  if (isRecurring) {
    return (
      <Button
        variant="primary"
        label="Edit this occurrence"
        icon={editIcon}
        onClick={() => navigate(`/edit-event/${eventId}?date=${occurrenceDate}`)}
      />
    );
  }
  return null; 
};


  // Button to cancel/reinstate event
  const buttonIsCancelled = () => (
    <Button
      variant="primary"
      label={isCancelled ? "Reinstate event" : "Cancel event"}
      icon={alertIcon}
      onClick={() => isCancelled ? setShowReinstateEventModal(true) : setShowCancelEventModal(true)}
    />
  );

  // Modal to confirm event cancellation
  const confirmCancelEvent = () => (
    <SlideUpModal
      isOpen={showCancelEventModal}
      onClose={() => setShowCancelEventModal(false)}
      title="Cancel event"
    >
      <div className="layout">
        <p>This will show the event as ‘Cancelled’. If this is a recurring event, only the selected occurrence will be cancelled.</p>
        <p>You can reinstate a cancelled event at any time.</p>
        <div style={{ marginBottom: '10px' }}></div>
        <Button
          variant="primary"
          label="Cancel event"
          icon={alertIcon}
          onClick={handleCancelEvent}
          className="grey"
        />
      </div>
    </SlideUpModal>
  );

  // Modal to confirm event reinstatement
  const confirmReinstateEvent = () => (
    <SlideUpModal
      isOpen={showReinstateEventModal}
      onClose={() => setShowReinstateEventModal(false)}
      title="Reinstate event"
    >
      <div className="layout">
        <p>Reinstate the event so that it is no longer cancelled.</p>
        <div style={{ marginBottom: '10px' }}></div>
        <Button
          variant="primary"
          label="Reinstate event"
          icon={tickIcon}
          onClick={handleReinstateEvent}
        />
      </div>
    </SlideUpModal>
  );

  // Handle delete event ------------------------------------
  const handleDeleteEvent = async () => {
    setIsDeleting(true);
    try {
      await deleteEvent(eventId);
      navigate('/manageevents');
    } catch (error) {
      console.error('Error deleting event:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  // Modal to confirm delete event
  const confirmDeleteEvent = () => (
    <SlideUpModal
      isOpen={showDeleteEventModal}
      onClose={() => setShowDeleteEventModal(false)}
      title="Delete event"
    >
      <div className="layout">
        <p>Deleting an event will permanently delete this event and any future occurrences of it.</p>
        <p>This action cannot be undone.</p>
        <div style={{ marginBottom: '10px' }}></div>
        <Button
          variant="primary"
          label="Delete event"
          icon={cancelIcon}
          onClick={handleDeleteEvent}
          className="red"
        />
      </div>
    </SlideUpModal>
  );


  // ---------------------------
  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      title="Event options"
    >
      <div className="layout">
        {buttonAnnouncement()}
        {confirmAnnouncement()}
        {buttonIsCancelled()}
        {confirmCancelEvent()}
        {confirmReinstateEvent()}
        {buttonEditOccurrence()}
        <Button
          variant="primary"
          label={isRecurring ? "Edit event series" : "Edit event"}
          icon={editIcon}
          onClick={() => navigate(`/edit-event/${eventId}`)}
        />
        <Button
          variant="primary"
          label="Copy event"
          icon={copyIcon}
          onClick={() => navigate(`/create-event?copiedEvent=${eventId}&date=${occurrenceDate}`)}
        />
        <Button
          variant="primary"
          label="Delete Event"
          icon={cancelIcon}
          onClick={() => setShowDeleteEventModal(true)}
          className="grey"
        />
        {confirmDeleteEvent()}
      </div>
    </SlideUpModal>
  );
};

export default EventOptions;
