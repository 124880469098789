// src/components/event-display/EventCard.js
import React, { useState, useEffect } from 'react';
import './EventCard.css';
import './EventSeries';
import iconLocation from '../../assets/icons/icon_location.svg';
import iconAlert from '../../assets/icons/icon_alert.svg';
import placeholder from '../../assets/icons/placeholder_event_small.png';
import EventSeries from './EventSeries';

const EventCard = ({ event, onClick, onSeriesClick, series = false }) => {
  const { eventId, eventName, eventDate, startTime, endTime, thumbnailUrl, isCancelled, announcementText, isRecurring, recurrenceDay, exceptions, location: { venue } = {} } = event || {};
  const [imageError, setImageError] = useState(false);

  const displaySeries = !!series && !!isRecurring || false; // Show event occurrences only when viewing event series that is recurring 


   // Generate eventDateDisplay logic
   const eventDateDisplay = (() => {
    if (series) {
      if (isRecurring) {
        // Convert recurrenceDay to weekday name and format as "Every {weekday}"
        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return <span>Every {weekdays[recurrenceDay]}</span>;
      } else {
        // Format eventDate as 'Tue 1st January 24'
        const date = new Date(eventDate);
        const formattedDate = date.toLocaleDateString('en-GB', {
          weekday: 'short',
          day: 'numeric',
          month: 'long',
          year: '2-digit',
        });
        return <span>{formattedDate}</span>;
      }
    } else {
      if (isCancelled) {
        return <span className="cancelled-text">Cancelled</span>;
      } else if (!isRecurring) {
        return <span>EVENT</span>;
      } else {
        return (
          <span>
            {startTime ? startTime : 'TBA'} - {endTime ? endTime : 'TBA'}
          </span>
        );
      }
    }
  })();


  return (
    <div 
      className={`event-card ${isCancelled ? 'cancelled' : ''} ${!isRecurring & !series ? 'event' : ''}`} 
      onClick={onClick} // <-- Make the card clickable
    >
      {/* Announcement section */}
      {announcementText && (
        <div className="announcement">
          <h3>ANNOUNCEMENT</h3>
          <p>{announcementText}</p>
        </div>
      )}

      <div className="event-content">
        {/* Image column */}
        <div className="event-image">
          <img
            src={(!imageError && thumbnailUrl) || placeholder}
            alt={eventName || 'Event'}
            onError={() => setImageError(true)}
          />
        </div>

        {/* Details column */}
        <div className="event-details">
          <div className="event-time">
            {eventDateDisplay}
          </div>

          <h2 className="event-name">{eventName || 'Untitled Event'}</h2>

          <div className="event-location">
            <img src={iconLocation} alt="Location icon" className="icon-location" />
            <span>{venue || 'Location not available'}</span>
          </div>
        </div>
      </div>

      {isCancelled && <img src={iconAlert} alt="Alert: Event cancelled" className="icon-alert" />}
      
      {displaySeries && <EventSeries 
        exceptions={exceptions}
        recurrenceDay={recurrenceDay}
        eventId={eventId}
        onSeriesClick={onSeriesClick}
      />}
    </div>
  );
};

export default EventCard;
