// src/components/EmailLoginScreen.js
import React, { useState } from 'react';
import { auth } from '../../firebaseConfig';
import { fetchSignInMethodsForEmail, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import Button from '../components/Button';
import Input from '../components/Input';

const EmailLoginScreen = ({ onBack, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoginMode, setIsLoginMode] = useState(true); // Default to login mode

  // Login existing user
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      onLoginSuccess(); // Call onLoginSuccess after successful login
    } catch (error) {
      setError(error.message);
    }
  };

  // Create a new user account
  const handleCreateAccount = async (e) => {
    e.preventDefault();
    setError(null);
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      onLoginSuccess(); // Call onLoginSuccess after successful account creation
    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-credential':
          setError("Incorrect email or password."); // More general message
          break;
        case 'auth/email-already-in-use':
          setError("Email is already in use.");
          break;
        case 'auth/weak-password':
          setError("Please make your password stronger.");
          break;
        case 'auth/user-not-found': // Add this one!
            setError("No user found with that email.")
            break;
        default:
          console.error("Firebase Error:", error); // Log the full error for debugging
          setError(error.message); // Fallback to the original message (for other errors)
      }
    }
  };

  const isLoginDisabled = email === '' || password === ''; // Disable login button if fields are empty
  const isCreateAccountDisabled = email === '' || password === '' || confirmPassword === ''; // Disable create account button

  return (
    <div className='page-content textBlock'>
      <p className='spacer-medium'>{isLoginMode ? 'Login or create a free account' : 'Create a free account'}</p>
      
      {isLoginMode ? (
        <form className='layout' onSubmit={handleLogin}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="primary"
            label="Login"
            type="submit"
            disabled={isLoginDisabled}
          />
          <Button
            variant="secondary"
            label="Create Account"
            type="button"
            onClick={() => {
              setIsLoginMode(false);
              setError(null);
            }}
          />
          {error && <p className="error-message">{error}</p>}
        </form>
      ) : (
        <form className='layout' onSubmit={handleCreateAccount}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Create Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Retype Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            variant="primary"
            label="Create Account"
            type="submit"
            disabled={isCreateAccountDisabled}
          />
          <Button
            variant="secondary"
            label="Back to Login"
            type="button"
            onClick={() => {
              setIsLoginMode(true);
              setError(null);
            }}
          />
          {error && <p className="error-message">{error}</p>}
        </form>
      )}

    </div>
  );
};

export default EmailLoginScreen;
