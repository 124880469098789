// src/components/components/SegmentedSwitch.js
import React from 'react';
import './SegmentedSwitch.css';

const SegmentedSwitch = ({
  options = [],
  selected = 0, // Accept the selected prop from the parent component
  size = 'medium',
  onSelect = () => {},
  disabled = false,      // Disabled state (false by default)
}) => {
  const handleSelect = (index) => {
    onSelect(index); // Call the onSelect function passed from the parent
  };

  return (
    <div className={`segmented-switch ${size} ${disabled ? 'disabled' : ''}`}>
      {options.map((option, index) => (
        <div
          key={index}
          className={`switch-item ${index === selected ? 'selected' : ''}`}
          onClick={() => handleSelect(index)}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default SegmentedSwitch;
