// src/components/edit-event/eventUpdate.js
/**
 * Function to handle saving minor changes to an event occurrence (exception update only)
 * @param {string} eventId - The event ID / firebase document ID.
 * @param {string} occurrenceDate - Date of the event occurrence to update.
 * @param {number|null} exceptionIndex - Array index of the exception for that occurrence date.
 * @param {object} updatedEventData - The event fields that have changed.
 * @returns {Promise<void>} - Resolves when the action is complete.
 */

import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from '../../firebaseConfig'; // Import Firebase DB config

const eventUpdate = async (eventId, occurrenceDate, exceptionIndex, updatedEventData) => {
  if (!eventId || !occurrenceDate) {
    throw new Error("Event ID and occurrenceDate are required.");
  }

  try {
    // Reference to the event document in Firestore
    const eventRef = doc(db, "events", eventId);
    const eventDoc = await getDoc(eventRef);

    if (!eventDoc.exists()) {
      throw new Error(`Event with ID ${eventId} not found.`);
    }

    const currentExceptions = eventDoc.data().exceptions || [];

    if (exceptionIndex === null || exceptionIndex === undefined) {
      // Create a new exception
      const newException = {
        date: occurrenceDate,
        ...updatedEventData,
      };

      await updateDoc(eventRef, {
        exceptions: [...currentExceptions, newException],
      });
    } else {
      // Update an existing exception
      const updatedExceptions = [...currentExceptions];
      if (!updatedExceptions[exceptionIndex]) {
        throw new Error(`Exception index ${exceptionIndex} is invalid.`);
      }

      updatedExceptions[exceptionIndex] = {
        ...updatedExceptions[exceptionIndex],
        ...updatedEventData,
      };

      await updateDoc(eventRef, { exceptions: updatedExceptions });
    }

    console.log(`Successfully updated event ${eventId} for date ${occurrenceDate}`);
  } catch (error) {
    console.error(`Failed to update event with ID ${eventId}:`, error);
    throw error;
  }
};

export default eventUpdate;
